import {Box, Button, Drawer, Grid, TextField, Typography} from '@mui/material'
import React, {useState} from 'react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {useWarningsContext} from '../../../../context/WarningsContext'
import {WarningElement, WordWarning} from '../../../../types/Warning'
import './Warnings.scss'
import {useFeedbackContext} from '../../../../context/FeedbackContext'
import {useOrganizationContext} from '../../../../context/OrganizationContext'
import {SelectWarningLevel} from './SelectWarningLevel'
import {WarningItem} from './WarningItem'
import {EditWordWarning} from './EditWordWarning'

export const Words = () => {

	const {user} = useUser()
	const {organization} = useOrganizationContext()
	const {showFeedback} = useFeedbackContext()
	const {wordWarnings, createWordWarning, removeWordWarning} = useWarningsContext()
	const {displayDrawerEditWordWarning, toggleDrawer} = useToggleDrawerContext()

	const [addingNewWordWarning, setAddingNewWordWarning] = useState<boolean>(false)
	const [wordValidError, setWordValidError] = useState<boolean>(false)
	const [newWord, setNewWord] = useState<string>('')
	const [warningLevel, setWarningLevel] = useState<string>('medium')
	const [wordWarningToEdit, setWordWarningToEdit] = useState<WordWarning | undefined>()
	const [validationError, setValidationError] = useState<string>('')

	const handleCancelSaveWordWarning = () => {
		setAddingNewWordWarning(false)
		setValidationError('')
		setWordValidError(false)
	}

	const handleDeleteWordWarningClicked = async (wordWarning: WarningElement) => {
		const error = await removeWordWarning(wordWarning as WordWarning)
		if (!error) {
			showFeedback('Word deleted', '', 'success', 5)
			return TrackActionEvent('Warnings', user?.id, {action: 'remove', type: 'word', organization_id: organization?.hashKey})
		}
		showFeedback('Word couldn\'t be deleted', 'Please, try it again', 'error', 5)
	}

	const handleSaveWordWarning = async () => {
		if (!newWord) {
			setWordValidError(true)
			setValidationError('Word is mandatory')
			return
		}
		if (wordWarnings.find(wordWarning => wordWarning.word === newWord)) {
			setWordValidError(true)
			setValidationError('That word already exists. Try adding a different one')
			return
		}
		if (newWord.trim().includes(' ')) {
			setWordValidError(true)
			setValidationError('You can only specify a single word. ')
			return
		}

		setWordValidError(false)
		const error = await createWordWarning(newWord.trim(), warningLevel)
		if (error) {
			setWordValidError(true)
			setValidationError(error)
			return
		}

		TrackActionEvent('Warnings', user?.id, {action: 'add', type: 'word', organization_id: organization?.hashKey})
		setNewWord('')
		setWarningLevel('medium')
		setAddingNewWordWarning(false)
		showFeedback('Word saved', '', 'success', 5)
	}

	const handleEditClicked = (event: React.MouseEvent<HTMLButtonElement>, wordWarning: WarningElement) => {
		setWordWarningToEdit(wordWarning as WordWarning)
		return toggleDrawer(true, 'EDIT_WORD_WARNING_DRAWER')(event)
	}

	return <>
		<Grid container className='settingContainer'>
			<Box className='sectionContainer'>
				<Grid item xs={12}>
					<Typography variant='h5' className='warningSubtitle'>Words</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className='body'>Use words to create a list of specific words that will always create a warning, no matter how they are used.</Typography>
				</Grid>
				{wordWarnings.length ? <Grid className='warningNameContainer'>
					{wordWarnings
						.map((wordWarning, index) =>
							<WarningItem warning={wordWarning} key={`warning-word${index}`} handleDeleteClicked={handleDeleteWordWarningClicked}
							             handleEditClicked={handleEditClicked} deleteEventName='wordWarningDeletion'/>)
					}
				</Grid> : <></>}
			</Box>
			{addingNewWordWarning ? <Grid container className='settingContainer'>
					<Grid item xs={12}>
						<Typography variant='h5' className='subsubtitle'>New word</Typography>
					</Grid>
					<Grid item xs={6} className='warningFieldContainer'>
						<TextField variant='outlined' label='Word' placeholder='Word 1' fullWidth className='newWarningTextfield' error={wordValidError}
						           helperText={wordValidError ? validationError : ''}
						           onChange={(event) => setNewWord(event.target.value)}/>
					</Grid>
					<Grid item xs={6}>
						<SelectWarningLevel setWarningLevel={setWarningLevel}/>
					</Grid>
					<Grid item xs={12}>
						<Button variant='contained' onClick={handleSaveWordWarning} className='saveButton'>Add new word</Button>
						<Button variant='outlined' onClick={handleCancelSaveWordWarning} className='cancelButton'>Cancel</Button>
					</Grid>
				</Grid> :
				<Grid container className='settingContainer'>
					<Grid item xs={12}>
						<Button onClick={() => setAddingNewWordWarning(true)} className='addDomainButton'>+ Add word</Button>
					</Grid>
				</Grid>
			}
		</Grid>
		<Drawer anchor='right' open={displayDrawerEditWordWarning} onClose={toggleDrawer(false, 'EDIT_WORD_WARNING_DRAWER')}>
			{wordWarningToEdit ? <EditWordWarning wordWarningToEdit={wordWarningToEdit}/> : <></>}
		</Drawer>
	</>
}