import {ChatMessagesContextValue} from '../context/ChatMessagesContext'
import {useCallback, useEffect, useState} from 'react'
import {getChatMessagesByChatId} from '../service/persistenceService'
import {useUserContext} from '../context/UserContext'
import {useErrorBoundary} from 'react-error-boundary'
import {ChatMessageStored} from '../types/ChatMessageStored'
import {ChatMessage} from '../types/ChatMessage'
import {getSortedRegeneratedOutputs, getStoredMessageProcessed} from '../utils/chatUtils'

export const useChatMessages = (): ChatMessagesContextValue => {

	const {token} = useUserContext()
	const {showBoundary} = useErrorBoundary()

	const [loading, setLoading] = useState<boolean>(false)
	const [chatId, setChatId] = useState<string | undefined>(undefined)
	const [chatMessages, setChatMessages] = useState<ChatMessageStored[]>([])

	const getChatMessages = useCallback(async () => {
		if (!chatId || chatMessages.length) return
		setLoading(true)
		await getChatMessagesByChatId(token, chatId)
			.then((chatMessages: ChatMessageStored[]) => {
				chatMessages.sort((a, b) => a.order - b.order)
				setChatMessages(chatMessages)
			})
			.catch(showBoundary)
			.finally(() => setLoading(false))
	}, [chatId, showBoundary, token, chatMessages.length])

	const groupMessagesByOrder = useCallback((chatMessages: ChatMessageStored[]): {[x: string] : ChatMessageStored[]} => {
		return chatMessages.reduce((acc, chatMessage) => {
			const chatMessageKey = `${chatMessage.chatId}-${chatMessage.order}`
			acc[chatMessageKey] = acc[chatMessageKey] || []
			acc[chatMessageKey].push(chatMessage)
			return acc
		}, {})
	}, [])

	const getGroupedMessagesProcessed = useCallback((groupedMessagesByOrder: { [p: string]: ChatMessageStored[] }): ChatMessage[] => {
		return Object.values(groupedMessagesByOrder)
			.map((chatMessagesGrouped: ChatMessageStored[]) => {
				if (chatMessagesGrouped.length > 1) {
					const maxOrder = Math.max(...chatMessagesGrouped.map(chatMessage => chatMessage.regeneratedMessageIndex ?? 0))
					const latestRegeneratedMessage = chatMessagesGrouped.find(chatMessage => chatMessage.regeneratedMessageIndex === maxOrder)!
					const regeneratedOutputs = getSortedRegeneratedOutputs(chatMessagesGrouped, latestRegeneratedMessage)
					return getStoredMessageProcessed(latestRegeneratedMessage, regeneratedOutputs)
				}
				return getStoredMessageProcessed(chatMessagesGrouped[0])
			})
	}, [])

	useEffect(() => {
		getChatMessages()
	}, [getChatMessages])

	return {
		loading,
		chatId, setChatId,
		chatMessages, setChatMessages,
		groupMessagesByOrder, getGroupedMessagesProcessed
	}
}