import {Grid, Typography} from '@mui/material'
import React from 'react'
import {Topics} from './Topics'
import { Words } from './Words'
import './Warnings.scss'
import {Format} from './Format'

export const Safeguard = () => {
  return <Grid container className='globalSettings'>
		<Grid item xs={12}>
			<Typography variant='h3' className='title'>Safeguard</Typography>
			<Typography variant='body1' className='warningSubtitle'>Identify what content trigger a warning. These warnings are shown in your Reports.</Typography>
			<Grid container className='settingContainer'>
				<Grid item xs={12}>
					<Typography variant='h4' className='sectionTitle'>Data Types</Typography>
				</Grid>
			</Grid>
			<Topics/>
			<Words/>

			<Grid container className='settingContainer'>
				<Grid item xs={12}>
					<Typography variant='h4' className='sectionTitle'>Format</Typography>
				</Grid>
			</Grid>
			<Format/>
		</Grid>
	</Grid>
}