import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useFeedbackContext} from '../context/FeedbackContext'
import {AIModel} from '../types/AiModel'
import {getAiModels, updateAiModel, removeAiModel, storeAiModel, storeApiKey} from '../service/persistenceService'
import {TrackActionEvent} from '../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import { AiModelsConfig, AiModelsContextValue } from '../context/AIModelsContext'

export const useAiModels = ({
    retrieveFeedbackVisible
}: AiModelsConfig): AiModelsContextValue => {
    const {token} = useUserContext()
    const {showFeedback} = useFeedbackContext()
    const {user} = useUser()

    const [loading, setLoading] = useState<boolean>(true)
    const [aiModels, setAiModels] = useState<AIModel[]>([])

    const retrieveAiModels = useCallback(() => {
        setLoading(true)
        getAiModels(token).then((models: AIModel[]) => {
            setLoading(false)
            setAiModels(models.sort((modelA: AIModel, modelB: AIModel) => modelA.name > modelB.name ? 1 : -1))
        }).catch(() => {
            if (retrieveFeedbackVisible) {
                showFeedback('Error', 'Something went wrong to load data. Try again reloading the page', 'error')
            }
            setAiModels([])
        }).finally(() => setLoading(false))
    }, [token, retrieveFeedbackVisible, showFeedback])


    const updateUiWithSelectedModel = useCallback((aiModel: AIModel) => {
        setAiModels((previousModels: AIModel[]) => {
            const aiModelIndex = previousModels.findIndex(prevModel => prevModel.id === aiModel.id)
            if (aiModelIndex > -1) {
                const updatedModels = [...previousModels]
                updatedModels[aiModelIndex] = aiModel
                return updatedModels
            }
            return previousModels
        })
    }, [])

    const updateAiModelEnablement = useCallback((isChecked: boolean, aiModel: AIModel) => {
        const originalAiModel = {...aiModel}
        const aiModelToUpdate: AIModel = {...aiModel, isEnabled: isChecked}
        updateUiWithSelectedModel(aiModelToUpdate)

        return updateAiModel(aiModel.id, token, aiModelToUpdate)
            .then(updatedModel => {
                if (!updatedModel) {
                    return Promise.reject('No AI model returned from update')
                }
                TrackActionEvent('AI model control', user?.id, {
                    action: isChecked ? 'enable' : 'disable',
                    model_id: aiModel.id
                })
                showFeedback('Success', `The AI model was successfully ${isChecked ? 'enabled' : 'disabled'}.`, 'success')
            })
            .catch((error) => {
                updateUiWithSelectedModel(originalAiModel)
                showFeedback('Error', 'Something went wrong fulfilling the action.', 'error')
                return Promise.reject(error)
            })
    }, [user?.id, token, showFeedback, updateUiWithSelectedModel])

    const deleteAiModel = useCallback((modelId: string) =>
        removeAiModel(modelId, token)
            .then(() => {
                TrackActionEvent('AI model control', user?.id, {
                    action: 'remove',
                    model_id: modelId
                })
                setAiModels(previousModels => previousModels.filter(model => model.id !== modelId))
                showFeedback('Success', 'The AI model was successfully removed.', 'success')
            }).catch((error) => {
                showFeedback('Error', 'Something went wrong fulfilling the action.', 'error')
                return Promise.reject(error)
            }), [user?.id, token, showFeedback])

    const addAiModel = useCallback((modelId: string, apiKey: string, modelName: string) =>
        storeAiModel(modelId, apiKey, token, modelName)
            .then((newAiModel: AIModel) => {
                setAiModels((previousModels: AIModel[]): AIModel[] => {
                    if (!newAiModel) return previousModels
                    return [...previousModels, newAiModel]
                })
                showFeedback('Success', 'The AI model has been successfully integrated', 'success')
                TrackActionEvent('AI model control', user?.id, {
                    action: 'connect',
                    model_id: newAiModel.id
                })
            }).catch((error) => {
                showFeedback('Error', 'Something went wrong fulfilling the action.', 'error')
                return Promise.reject(error)
            }), [user?.id, token, showFeedback])

    const updateAiModelConfig = useCallback((apiKey: string | undefined, modelName: string, isUpdate: boolean, modelId: string) => 
        storeApiKey(apiKey, token, modelId, isUpdate, modelName).then(() => {
            setAiModels((previousModels) => {
                const aiModelIndex = previousModels.findIndex(prevModel => prevModel.id === modelId)
                if (aiModelIndex > -1) {
                    const updatedModels = [...previousModels]
                    updatedModels[aiModelIndex] = {...updatedModels[aiModelIndex], name: modelName} as AIModel
                    return updatedModels
                }
                return previousModels
            })
            TrackActionEvent('AI model control', user?.id, {
                action: 'save',
                user_id: user?.id
            })
            showFeedback('Success', 'The AI model\'s API KEY has been successfully updated.', 'success')
        }).catch((error) => {
            showFeedback('Error', 'Something went wrong fulfilling the action.', 'error')
            return Promise.reject(error)
        }), [user?.id, token, showFeedback])

    useEffect(() => {
        retrieveAiModels()
    }, [retrieveAiModels])

    return useMemo(() => ({
        loading,
        aiModels,
        addAiModel,
        deleteAiModel, 
        updateAiModelConfig,
        updateAiModelEnablement, 
    }), [loading, aiModels, updateAiModelEnablement, deleteAiModel, addAiModel, updateAiModelConfig])
}
