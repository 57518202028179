import { waitTime } from './timeoutHelpers'

/**
 * Executes an async callback with a maximum number of retries, and waits a time between them
 * @param callback the callback to be executed
 * @param maxRetries the maximun number of retries
 * @param waitMilliseconds the time in milliseconds to wait between callbacks
 * @returns callback success result, or last retried callback error
 */
export const retryAsyncCallback = <T extends any[], U>(callback: (...params: T) => Promise<U>, maxRetries: number, waitMilliseconds: number) => async (...params: T): Promise<U> => {
    let tries = 0
    let storedError

    while (tries < maxRetries) {
        tries++
        try {
            const response = await callback(...params)
            return response
        } catch (error) {
            storedError = error
        }
        if (tries < maxRetries && waitMilliseconds) {
            await waitTime(waitMilliseconds)
        }
        
    }

    return Promise.reject(storedError)
}