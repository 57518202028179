import {createContext, ReactNode, useContext} from 'react'
import {UserInfo} from '../types/UserInfo'
import {useUserInfo} from '../hooks/useUserInfo'
import Spinner from '../components/spinner/Spinner'

interface UserInfoContextProviderProps {
	children: ReactNode
}

export interface UserInfoContextValue {
	loading: boolean
	userInfo: UserInfo | undefined
}

const DEFAULT_STATISTIC_CONTEXT: UserInfoContextValue = {
	loading: false,
	userInfo: undefined,
}

export const UserInfoContext = createContext<UserInfoContextValue>(DEFAULT_STATISTIC_CONTEXT)

export const useUserInfoContext = () => useContext(UserInfoContext)

export const UserInfoContextProvider = ({children}: UserInfoContextProviderProps) => {
	const value = useUserInfo()
	const { userInfo } = value

	return <UserInfoContext.Provider value={value}>
		{ userInfo ? children : <Spinner/> }
	</UserInfoContext.Provider>
}