import { FC } from 'react'
import { ACCOUNT_CREATION_CONFIG } from '../../constants/AccountCreationConstants'
import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { NarusLogo } from '../narusLogo/NarusLogo'
import './AccountCreationAside.scss'
import { StepIndex, StepIndexes } from '../../types/AccountCreation'

type AccountCreationAsideProps = {
    index: StepIndex
}

export const AccountCreationAside: FC<AccountCreationAsideProps> = ({
    index
}) => {
    return <Grid container direction={'column'} className='AccountCreationAside'>
        <Grid item xs={3}>
            <Box className='AccountCreationAside_Header'>
                <Box className='AccountCreationAside_Logo'>
                    <NarusLogo width={51} height={51}/>
                    <Typography variant='h3'>Narus</Typography>
                </Box>
                <Typography className='AccountCreationAside_Title' variant='h3'>Set up your company</Typography>
            </Box>
        </Grid>
        <Grid item xs={6}>
            <Box className='AccountCreationAside_Content'>
                <Stepper activeStep={index} orientation='vertical'>
                    { StepIndexes.map(value => <Step key={value}>
                        <StepLabel>{ACCOUNT_CREATION_CONFIG[value].title}</StepLabel>
                    </Step>)}
                </Stepper>
            </Box>
        </Grid>
        <Grid item xs={3} />
    </Grid>
}