import React, {useEffect} from 'react'
import {PageView} from '../../service/SegmentService'
import HeaderMenu from '../headerMenu/HeaderMenu'
import { Grid } from '@mui/material'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {usePromptsContext} from '../../context/PromptsContext'

export const InspirationPrompts = () => {

	const {prompts, lastEvaluatedKey} = usePromptsContext()

	useEffect(() => PageView('Inspiration prompts'), [])

	return <Grid container >
		<Grid xs={12} item>
			<HeaderMenu/>
		</Grid>
		<Grid xs={12} item>
			<PromptsCards prompts={prompts} showLoadMore={lastEvaluatedKey !== undefined}
			              noPromptsMessage='There are no prompts in this library yet'/>
		</Grid>
	</Grid>
}