import React, {useEffect, useState} from 'react'
import {getAdminTabForAnalytics, PageView, TrackActionEvent} from '../../service/SegmentService'
import {Grid, Tab, Tabs} from '@mui/material'
import {CustomTabPanel} from '../homeTabs/HomeTabs'
import {useUser} from '@clerk/clerk-react'
import {NarusLogo} from '../narusLogo/NarusLogo'
import Box from '@mui/material/Box'
import './Admin.scss'
import {UserUsageTable} from './charts/UserUsageTable'
import {AiUsageChart} from './charts/AiUsageChart'
import {UserProfileMenu} from '../headerMenu/profileMenu/ProfileMenu'
import {CostPerAIModel} from './charts/CostPerAIModel'
import {AdminErrorScreen} from './error/AdminErrorScreen'
import {CompanyWorkspace} from './charts/CompanyWorkspace'
import {CustomDateRangePicker} from './CustomDateRangePicker'
import {ConfigurationAdmin} from './configuration/ConfigurationAdmin'
import {AuditingLogsTable} from './audit/AuditingLogsTable'
import { useUserInfoContext } from '../../context/UserInfoContext'

export const Admin = () => {

	const { userInfo } = useUserInfoContext()
	const {user} = useUser()
	const isAdmin = userInfo?.isAdmin

	const [selectedTab, setSelectedTab] = useState(0)

	useEffect(() => {
		if (isAdmin) PageView('Narus admin')
	}, [isAdmin])

	const selectTabHandler = (newValue: number) => {
		setSelectedTab(newValue)
		TrackActionEvent('Admin tab', user?.id, {section: getAdminTabForAnalytics(newValue)})
	}

    return <>
        {isAdmin ? <>
            <Grid container className='adminContainer adminHeaderContainer admin'>
                <Grid xs={12} className='headerWrapper' item>
                    <Box className='header admin'>
                        <Box className='headerWelcome' onClick={() => selectTabHandler(0)}>
                            <NarusLogo height={51} width={51}/>
                        </Box>
                        <UserProfileMenu/>
                    </Box>
                </Grid>

			<Grid xs={12} item>
				<Tabs value={selectedTab} onChange={(_: React.SyntheticEvent, newValue: number) => selectTabHandler(newValue)}
				      className='adminTabContainer' variant='scrollable'>
					<Tab label='Overview'/>
					<Tab label='Reports'/>
					<Tab label='Configuration'/>
				</Tabs>
			</Grid>
		</Grid>
		<Grid container className='adminContainer adminTabWrapper'>
			<Grid xs={12} item>
				<CustomTabPanel value={selectedTab} index={0}>
					<Grid item xs={12} className='datePickerContainer'>
						<CustomDateRangePicker currentTab='OVERVIEW'/>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={3}>
							<CompanyWorkspace/>
						</Grid>
						<Grid item xs={9} container>
							<Grid item xs={7}>
								<AiUsageChart/>
							</Grid>
							<Grid item xs={5}>
								<CostPerAIModel/>
							</Grid>
							<Grid item xs={12}>
								<UserUsageTable/>
							</Grid>
						</Grid>
					</Grid>
				</CustomTabPanel>
				<CustomTabPanel value={selectedTab} index={1}>
					<AuditingLogsTable/>
				</CustomTabPanel>
				<CustomTabPanel value={selectedTab} index={2}>
					<ConfigurationAdmin/>
				</CustomTabPanel>
			</Grid>
		</Grid>
	</> : <AdminErrorScreen/>}
    </>
}