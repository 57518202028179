import React, {ReactNode} from 'react'
import {StatisticsContextProvider} from '../../context/StatisticsContext'
import {UsersAccessContextProvider} from '../../context/UsersAccessContext'
import {ToggleDrawerContextProvider} from '../../context/ToggleDrawerContext'
import {AiModelsContextProvider} from '../../context/AIModelsContext'
import {AuditingLogsContextProvider} from '../../context/AuditingLogsContext'
import {OrganizationContextProvider} from '../../context/OrganizationContext'
import {AdminEmailsContextProvider} from '../../context/AdminEmailsContext'
import {UserInfoContextProvider} from '../../context/UserInfoContext'
import {WarningsContextProvider} from '../../context/WarningsContext'

interface AdminContextProviderProps {
	children: ReactNode
}

export const AdminContextProvider = ({children}: AdminContextProviderProps) => {
	return <UserInfoContextProvider>
		<UsersAccessContextProvider>
			<ToggleDrawerContextProvider>
				<AiModelsContextProvider>
					<StatisticsContextProvider>
						<AuditingLogsContextProvider>
							<OrganizationContextProvider>
								<AdminEmailsContextProvider>
									<WarningsContextProvider>
										{children}
									</WarningsContextProvider>
								</AdminEmailsContextProvider>
							</OrganizationContextProvider>
						</AuditingLogsContextProvider>
					</StatisticsContextProvider>
				</AiModelsContextProvider>
			</ToggleDrawerContextProvider>
		</UsersAccessContextProvider>
	</UserInfoContextProvider>
}