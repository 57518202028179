import React, {useEffect} from 'react'
import {PageView} from '../../service/SegmentService'
import {Grid} from '@mui/material'
import HeaderMenu from '../headerMenu/HeaderMenu'
import {ChatCards} from './ChatCards'

export const Chats = () => {
	useEffect(() => PageView('Chats'), [])

	return <Grid container>
		<Grid xs={12} item>
			<HeaderMenu/>
		</Grid>
		<Grid xs={12} item>
			<ChatCards/>
		</Grid>
	</Grid>
}