import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo} from 'react'
import {UserAuditLog} from '../types/UserAuditLog'
import {useAuditingLogs} from '../hooks/useAuditingLogs'
import {DateRange} from 'rsuite/DateRangePicker'

interface AuditingLogsContextProviderProps {
	children: ReactNode
}

export interface AuditingLogsContextValue {
	loading: boolean
	auditingLogs: UserAuditLog[]
	setDateRangeFilter: Dispatch<SetStateAction<DateRange | undefined>>
}

const DEFAULT_AUDITING_LOGS_CONTEXT: AuditingLogsContextValue = {
	loading: false,
	auditingLogs: [],
	setDateRangeFilter: (dateRangeFilter: SetStateAction<DateRange | undefined>) => {}
}

export const AuditingLogsContext = createContext<AuditingLogsContextValue>(DEFAULT_AUDITING_LOGS_CONTEXT)

export const useAuditingLogsContext = () => useContext(AuditingLogsContext)

export const AuditingLogsContextProvider = ({children}: AuditingLogsContextProviderProps) => {
	const {loading, auditingLogs, setDateRangeFilter} = useAuditingLogs()

	const value: AuditingLogsContextValue = useMemo(() => ({loading, auditingLogs, setDateRangeFilter}),
		[loading, auditingLogs, setDateRangeFilter])

	return <AuditingLogsContext.Provider value={value}>
		{children}
	</AuditingLogsContext.Provider>
}