import { ChangeEvent, FC, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, TextField, Tooltip, Typography } from '@mui/material'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { areAdminEmailsValid, getAdminEmail, isAdminEmailValid } from '../../utils/accountCreationHelpers'
import { useUser } from '@clerk/clerk-react'

export const AccountCreationFormAdminEmails: FC = () => {
    const { form, changeFormValue, changeFormValueValid } = useAccountCreationContext()
    const { adminEmails } = form
    const showAddAdminButton = true //adminEmails.length < 2 //Temporal requested feature bypass
    
    const handleAddAdminClicked = () => {
        changeFormValue('adminEmails', [...adminEmails, ''])
        changeFormValueValid('adminEmails', undefined)
    }
    
    return <Box className='AccountCreationForm_Control'>
        <Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
            Include the email from that domain that you want to be admins.
        </Typography>
        <AccountCreationFormAdminEmailFixed/>
        { adminEmails.map((_,i) => <AccountCreationFormAdminEmailControl key={i} index={i} />) }
        { showAddAdminButton 
            ? <Box>
                <Button onClick={handleAddAdminClicked}>+ Add admins</Button>
            </Box>
            : <Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
                You have reached the max. number of admins. Remove an existing admin if you want to add a different one.
            </Typography>}
    </Box>
}

const AccountCreationFormAdminEmailControl: FC<{index: number}> = ({
    index,
}) => {
    const { form, error, changeFormValue, changeFormValueValid, changeFormValueError } = useAccountCreationContext()
    const [validError, setValidError] = useState<boolean>(false)
    const { adminEmails, companyDomains } = form
    const [firstDomain] = companyDomains
    const email = adminEmails[index] ?? ''
    const showError = validError || !!error.adminEmails
    const helperText = showError ? (error.adminEmails || `Admin email has to follow the example ${firstDomain}`) : ''
    const placeholder = companyDomains.map(domain => `example${domain}`).join(', ')

    const getUpdatedAdminEmails = (value: string): string[] => [...adminEmails.slice(0,index), value, ...adminEmails.slice(index + 1)]

    const handleDeleteClicked = () => {
        const updatedEmails = [...adminEmails.slice(0,index), ...adminEmails.slice(index + 1)]
        changeFormValue('adminEmails', updatedEmails)
        changeFormValueValid('adminEmails', areAdminEmailsValid(updatedEmails, companyDomains))
    }

    const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const updatedEmails = getUpdatedAdminEmails(event.target.value)
        changeFormValue('adminEmails', updatedEmails)
    }

    const handleInputBlurred = () => {
        const value = getAdminEmail(email)
        const updatedEmails = getUpdatedAdminEmails(value)
        setValidError(value ? !isAdminEmailValid(companyDomains)(value) : false)
        changeFormValue('adminEmails', updatedEmails)
        changeFormValueValid('adminEmails', areAdminEmailsValid(updatedEmails, companyDomains))
    }

    const handleInputFocus = () => {
        setValidError(false)
        changeFormValueError('adminEmails', undefined)
        changeFormValueValid('adminEmails', undefined)
    }

    return <div className='AccountCreationForm_ControlItem'>
        <TextField variant='outlined' label='Admin email' error={showError} helperText={helperText} value={email} onFocus={handleInputFocus} onBlur={handleInputBlurred} onChange={handleInputChanged} placeholder={placeholder}/>
        <DeleteIcon onClick={handleDeleteClicked}/>
    </div>
}

const AccountCreationFormAdminEmailFixed: FC = () => {
    const { user } = useUser()

    return <Tooltip title='You are an admin by default. You can remove this later if you add new admins'>
        <div className='AccountCreationForm_ControlItem'>
            <TextField variant='outlined' label='Admin email' disabled value={user?.emailAddresses[0].emailAddress}/>
            <DeleteIcon/>
        </div>
    </Tooltip>
}