import {ClerkProvider, RedirectToSignIn, SignedIn, SignedOut} from '@clerk/clerk-react'
import {Outlet} from 'react-router-dom'
import {UserContextProvider} from './context/UserContext'
import {ThemeProvider} from '@mui/material'
import {FeedbackContextProvider} from './context/FeedbackContext'
import FeedbackMessage from './components/feedbackMessage/FeedbackMessage'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorSection from './error/ErrorSection'
import {customTheme} from './themes/CustomThemes'
import ShareFeedback from './components/shareFeedback/ShareFeedback'
import MobileBanner from './components/mobileBanner/MobileBanner'
import {UserOrganizationContextProvider} from './context/UserOrganizationContext'
import {AppGridWrapper} from './components/appGridWrapper/AppGridWrapper'

const App = () => {
	if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
		throw new Error("Missing Publishable Key")
	}
	const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

	return (
		<ClerkProvider publishableKey={clerkPubKey}>
			<ErrorBoundary FallbackComponent={ErrorSection}>
				<SignedIn>
					<UserContextProvider>
						<UserOrganizationContextProvider>
							<FeedbackContextProvider>
								<ThemeProvider theme={customTheme}>
									<AppGridWrapper>
										<Outlet/>
										<FeedbackMessage/>
										<ShareFeedback/>
										<MobileBanner/>
									</AppGridWrapper>
								</ThemeProvider>
							</FeedbackContextProvider>
						</UserOrganizationContextProvider>
					</UserContextProvider>
				</SignedIn>
				<SignedOut>
					<RedirectToSignIn/>
				</SignedOut>
			</ErrorBoundary>
		</ClerkProvider>
	)

}

export default App
