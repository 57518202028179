import { UserOrganization, UserOrganizationContextValue } from '../types/UserOrganization'

export const DEFAULT_USER_ORGANIZATION: UserOrganization = {
    organizationId: '',
    tableSuffix: '',
    userId: '',
}

export const DEFAULT_USER_ORGANIZATION_CONTEXT_VALUE: UserOrganizationContextValue = {
    userOrganization: DEFAULT_USER_ORGANIZATION,
    setUserOrganization: () => {},
}
