import React, {useCallback, useEffect, useState} from 'react'
import {Box, Button, Grid, Stack, Typography, Paper, Switch} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'
import CreatableSelect from 'react-select/creatable'
import ChipsLabelTeam from '../chipsLabelTeam/ChipsLabelTeam'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {usePromptsContext} from '../../context/PromptsContext'
import {AIModels} from '../../types/AiModel'
import './SaveChatCompilation.scss'
import {SingleValue} from 'react-select'
import {SelectOption} from '../../types/SelectOption'
import {useAbortController} from '../../hooks/useAbortController'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {Prompt} from '../../types/Prompt'
import {getHomeTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useUserContext} from '../../context/UserContext'
import {usePromptEdition} from '../../hooks/usePromptEdition'
import {SaveChatCompilationProps} from '../../types/SaveChatCompilationProps'
import {MarkdownEditor} from '../aiPromptTextarea/MarkdownEditor'
import {useNavigate} from 'react-router-dom'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {useAiModelsContext} from '../../context/AIModelsContext'

const SaveChatCompilation = ({promptChatDetails, tempPromptId}: SaveChatCompilationProps) => {

    const {modelId: currentModelId, messages} = promptChatDetails
    const {toggleDrawer, handleCloseSaveChatDrawer} = useToggleDrawerContext()
    const navigate = useNavigate()
    const {aiModels} = useAiModelsContext()

    const {
        promptId,
        promptTeams,
        language,
        frequencyPenalty,
        temperature,
        aiPromptCompilationLoading,
        title, setTitle,
        description, setDescription,
        promptLabels, setPromptLabels,
        promptChats, setPromptChats,
        isPublic, setIsPublic,
        clearPromptFields,
        createPrompt,
        getPromptCompilation,
        getPromptTemplate,
        setUnsavedChanges,
        setValueHandler,
    } = usePromptCreationContext()
    const {labels,setLabels} = usePromptsContext()
    const {abortControllerRefSignal} = useAbortController()
    const {showFeedback} = useFeedbackContext()
    const {user} = useUser()
    const {selectedTab, token} = useUserContext()
    const {editPrompt} = usePromptEdition(token)

    const [promptCompilationExecuting, setPromptCompilationExecuting] = useState<boolean>(false)

    const compilationSummary = promptChats.find(chat => chat.modelId === currentModelId)?.compilationSummary

    const generatePromptCompilationHandler = useCallback(async () => {
        if (aiPromptCompilationLoading) return
        const userMessages = messages.filter(message => message.sender === 'user').map(message => message.text)
        setPromptChats(prevValue =>
            prevValue.some(chat => chat.modelId === currentModelId) ?
                prevValue.map(chat => chat.modelId === currentModelId ? {...chat, compilationSummary: {summary: ''}} : chat)
                : prevValue)

        return getPromptCompilation({
            userPrompt: '',
            format: '',
            temperature: '',
            frequencyPenalty: '',
            language: '',
            audience: '',
            aiPrompt: '',
            description: '',
            title: '',
            tone: '',
            labels: [],
            teams: [],
            modelId: currentModelId,
            isPublic: false,
        }, abortControllerRefSignal, userMessages, promptId || tempPromptId).then(aiCompilation => {
            if (!aiCompilation && userMessages.length > 1) {
                showFeedback('Error', 'The AI model selected could not return a response. Please try again or use a different model.', 'error', 10)
            }
        }).catch(() => showFeedback('Error', 'The AI model selected could not return a response. Please try again or use a different model.', 'error', 10))
    }, [showFeedback, abortControllerRefSignal, messages, promptId, currentModelId, getPromptCompilation, tempPromptId, aiPromptCompilationLoading, setPromptChats])

    const savePromptHandler = async () => {
        createPrompt({...getPromptTemplate(), modelId: currentModelId, aiPrompt: compilationSummary?.summary || '', tempPromptId})
            .then((createdPrompt?: Prompt) => {
                if (createdPrompt) {
                    showFeedback('Prompt saved!', 'The prompt was saved!', 'success')
                    setUnsavedChanges(false)
                } else showFeedback('Error', 'The prompt couldn\'t be saved, please try again.', 'error')
            })
            .catch(() => showFeedback('Error', 'The prompt couldn\'t be saved, please try again.', 'error'))
            .finally(() => {
                TrackActionEvent('Prompt', user?.id, {
                    action: 'save',
                    origin: getHomeTabForAnalytics(selectedTab),
                    prompt_id: promptId,
                    is_public: isPublic
                })
            })
    }

    const updatePromptHandler = async () => {
        if (!promptId) {
            showFeedback('Error', 'No prompt found!', 'error')
            return
        }
        const updatedPrompt = {id: promptId, ...getPromptTemplate(), aiPrompt: compilationSummary?.summary || ''}
        await editPrompt(updatedPrompt)
            .then(() => {
                showFeedback('Success', 'The prompt was updated!', 'success')
                setUnsavedChanges(false)
                clearPromptFields()
                navigate('/')
            })
            .catch(() => showFeedback('Error', 'There was an error updating the prompt! Please, try again', 'error', 10))
            .finally(() => {
                TrackActionEvent('Prompt', user?.id, {
                    action: 'update',
                    origin: getHomeTabForAnalytics(selectedTab),
                    prompt_id: promptId,
                    is_public: isPublic
                })
            })
    }

    const saveChatCompilationHandler = async () => {
        const analyticsSaveChatCompilation = {action: 'save'}
        if (promptId) analyticsSaveChatCompilation['prompt_id'] = promptId
        TrackActionEvent('Save chat compilation', user?.id, analyticsSaveChatCompilation)
        !promptId ? await savePromptHandler() : await updatePromptHandler()
        handleCloseSaveChatDrawer('Save chat compilation', promptId)
    }

    useEffect(() => {
        if (!promptCompilationExecuting && (!compilationSummary || Object.keys(compilationSummary).length === 0 || compilationSummary?.isChatUpdated)) {
            setPromptCompilationExecuting(true)
            generatePromptCompilationHandler()
        }
    }, [generatePromptCompilationHandler, promptCompilationExecuting, promptChats, compilationSummary])

    const labelsHandler = (selectedLabel: SingleValue<SelectOption>) => {
        setValueHandler(setPromptLabels, promptLabels.indexOf(selectedLabel!.value) === -1 ? [selectedLabel!.value, ...promptLabels] : promptLabels, 'labels')
        setValueHandler(setLabels, labels.indexOf(selectedLabel!) === -1 ? [selectedLabel!, ...labels] : labels, 'labelOptions')
    }

    const changePromptSummaryHandler = (value: string) => {
        setPromptChats((prevValue: PromptChatDetail[]) => {
            const chatIndex = prevValue.findIndex(chat => chat.modelId === currentModelId)
            if (chatIndex === -1) return prevValue

            const updatedChat: PromptChatDetail = {...prevValue[chatIndex], compilationSummary: { summary: value }}
            return [...prevValue.slice(0, chatIndex), updatedChat, ...prevValue.slice(chatIndex + 1)]
        })
    }

    return <Stack className='savePromptContainer'>
        <Box className='savePromptTitle'>
            <Typography variant='h4'>Save progress</Typography>
            <CloseIcon onClick={toggleDrawer(false, 'SAVE_CHAT_COMPILATION_DRAWER')}
                       cursor='pointer'
                       className='closeIcon'/>
        </Box>
        <Box className='saveChatDescriptionText'>
            <Typography variant='caption' className='saveChatDescriptionText'>Your chat has been simplified into a single prompt.
                You can edit this prompt before you save.</Typography>
        </Box>
        <Box className='savePromptCompilationContainer'>
            <Typography variant='body2' className='fieldLabel'>Prompt compilation *</Typography>
            <Box className={'promptCompilationField'}>
                <MarkdownEditor aiText={compilationSummary?.summary || ''} label={'prompt'} loadingText='' isLoading={false}
                                 placeholderValue='Final prompt...'
                                 onChangeHandler={changePromptSummaryHandler}/>
            </Box>
            <Paper className='savePromptCompilationDescription'>
                <Typography variant='caption'>The new prompt may give slightly different results when used.</Typography>
            </Paper>
        </Box>
        <Box className='savePromptName'>
            <Typography variant='body2' className='fieldLabel'>Name *</Typography>
            <TextField
                required
                inputProps={{maxLength: 150}}
                className='textFieldInput textFieldPromptName'
                onChange={event => setValueHandler(setTitle, event.target.value, 'title')}
                placeholder='My amazing prompt'
                fullWidth
                variant='outlined'
                value={title}
            />
        </Box>
        <Box className='savePromptDescription'>
            <Typography variant='body2' className='fieldLabel'>Description</Typography>
            <TextField
                inputProps={{maxLength: 600}}
                className='textFieldInput textFieldPromptDescription'
                onChange={event => setValueHandler(setDescription, event.target.value, 'description')}
                placeholder='Use this prompt to generate something amazing'
                multiline
                fullWidth
                variant='outlined'
                value={description}
            />
        </Box>
        <Typography className='fieldLabel' variant='body2'>Label</Typography>
        <CreatableSelect isSearchable options={labels}
                         value={null}
                         placeholder='Add labels to find prompts easier. Eg. Marketing'
                         className='creatableSelect labelCreatableSelect'
                         onChange={labelsHandler}/>
        <ChipsLabelTeam chips={promptLabels || []} isLabel/>
        <ChipsLabelTeam chips={promptTeams || []}/>
        <Box className='privacyContainer'>
            <Typography variant='h4' className='settingsText'>Make it public</Typography>
            <Box className='privacyToggleContainer'>
                <Typography variant='caption'>
                    Share the prompt with the workspace
                </Typography>
                <Switch checked={isPublic} onChange={event => setValueHandler(setIsPublic, event.target.checked, 'isPublic')} />
            </Box>
        </Box>
        <Grid display='flex' container className='settingsContainer'>
            <Grid item xs={12} md={12}>
                <Typography variant='h4' className='settingsText'>Settings</Typography>
                <Grid container display='flex'>
                    <Grid item xs={3} className='promptInputFieldContainer'>
                        <Typography className='promptInputLabel' variant='subtitle1'>Model</Typography>
                        <Typography className='promptInputValue'
                                    variant='body2'>{aiModels?.find(({id}) => id === currentModelId)?.name ?? AIModels[currentModelId]?.name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className='promptInputLabel' variant='subtitle1'>Language</Typography>
                        <Typography className='promptInputValue' variant='body2'>{language || '-'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className='promptInputLabel' variant='subtitle1'>Creativity</Typography>
                        <Typography className='promptInputValue' variant='body2'>{temperature}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className='promptInputLabel' variant='subtitle1'>Language complexity</Typography>
                        <Typography className='promptInputValue' variant='body2'>{frequencyPenalty}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Box className='saveChatButtonContainer'>
            <Button className='saveChatButton' variant='contained'
                    onClick={saveChatCompilationHandler}
                    disabled={title.length === 0 || compilationSummary?.summary?.length === 0}>
                Save
            </Button>
        </Box>
    </Stack>
}

export default SaveChatCompilation