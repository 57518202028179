const debounce = (func: Function, wait: number) => {
    let timeout: ReturnType<typeof setTimeout> | undefined
    return (...args: any[]) => {
        if (timeout) clearTimeout(timeout)

        timeout = setTimeout(() => func(...args), wait)
    }
}

const processLiElements = () => {
        document.querySelectorAll('li').forEach((li) => {
        if (li.classList.contains('_nestedListItem_1tncs_59') && li.style.listStyleType !== 'none') {
            li.style.listStyleType = 'none'
        }
    })
}

export const debouncedProcessLiElements = debounce(processLiElements, 100)