import React from 'react'
import {AIModelID} from '../../types/AiModel'
import {ReactComponent as OpenAiIcon} from '../../images/openai.svg'
import {ReactComponent as GoogleIcon} from '../../images/google.svg'
import {ReactComponent as LlamaIcon} from '../../images/llama.svg'
import {ReactComponent as MistralIcon} from '../../images/mistral.svg'
import {ReactComponent as ClaudeIcon} from '../../images/claude.svg'
import {ReactComponent as AmazonQIcon} from '../../images/amazon-q.svg'
import {AiModelIconProps} from '../../types/AiModelIconProps'

export const AiModelIcon = ({modelId, className}: AiModelIconProps) => {
	switch (modelId) {
		case AIModelID.MISTRAL:
			return <MistralIcon className={className}/>
		case AIModelID.LLAMA_2:
			return <LlamaIcon className={className}/>
		case AIModelID.GOOGLE_GEMINI:
			return <GoogleIcon className={className}/>
		case AIModelID.CLAUDE:
			return <ClaudeIcon className={className}/>
		case AIModelID.AMAZON_Q:
			return <AmazonQIcon className={className}/>
		default:
			return <OpenAiIcon className={className}/>
	}
}