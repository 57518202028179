import * as ReactDOM from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import './index.css'
import App from './App'
import IntegrationField from './routes/IntegrationFeature/IntegrationFeature'
import PromptsList from './routes/promptsList/PromptsList'
import PromptDetail from './routes/promptDetail/PromptDetail'
import {LoadAnalytics} from './service/SegmentService'
import {AiChat} from './components/aiChat/AiChat'
import {AdminContextProvider} from './components/contextProviders/AdminContextProvider'
import {Admin} from './components/admin/Admin'
import {HomeContextProvider} from './components/contextProviders/HomeContextProvider'
import {NoModelsEnabledError} from './components/error/NoModelsEnabledError'
import ErrorSection from './error/ErrorSection'
import { AccountCreationWrapper } from './components/accountCreation/AccountCreationWrapper'
import { AccountCreationStep } from './components/accountCreation/AccountCreationStep'
import { Chats } from './components/chat/Chats'
import { MyPrompts } from './components/myPrompts/MyPrompts'
import { InspirationPrompts } from './components/inspirationPrompts/InspirationPrompts'
import {SavedChat} from './components/chat/SavedChat'

if (process.env.REACT_APP_SEGMENT_ENABLED === 'true') {
	LoadAnalytics()
}

const router = createBrowserRouter([
	{
		path: '/',
		id: 'root',
		element: <App/>,
		errorElement: <ErrorSection error={''}/>,
		children: [{
			path: '',
			element: <HomeContextProvider><PromptsList/></HomeContextProvider>
		}, {
			path: 'integration',
			element: <HomeContextProvider><IntegrationField/></HomeContextProvider>
		}, {
			path: 'prompt/:promptId/copy',
			element: <HomeContextProvider><PromptDetail copy={true}/></HomeContextProvider>
		}, {
			path: 'prompt/:promptId',
			element: <HomeContextProvider><PromptDetail/></HomeContextProvider>
		}, {
			path: 'prompt/:promptId/execute',
			element: <HomeContextProvider><PromptDetail runAiPrompt={true}/></HomeContextProvider>
		}, {
			path: 'aiChat/optimized',
			element: <HomeContextProvider><AiChat isPromptOptimized={true}/></HomeContextProvider>
		}, {
			path: 'aiChat',
			element: <HomeContextProvider><AiChat/></HomeContextProvider>
		}, {
			path: 'aiChat/:chatId',
			element: <HomeContextProvider><SavedChat/></HomeContextProvider>
		}, {
			path: 'admin',
			element: <AdminContextProvider><Admin/></AdminContextProvider>
		}, {
			element: <AccountCreationWrapper/>,
			children: [
				{ path: 'create-account-basic-information', element: <AccountCreationStep index={0} />,  },
				{ path: 'create-account-assign-admins', element: <AccountCreationStep index={1} /> },
				{ path: 'create-account-connect-ai', element: <AccountCreationStep index={2} /> },
			]
		}, {
			path: 'noModelsEnabled',
			element: <HomeContextProvider><NoModelsEnabledError/></HomeContextProvider>
		}, {
			path: 'inspirationPrompts',
			element: <HomeContextProvider><InspirationPrompts/></HomeContextProvider>
		}, {
			path: 'chats',
			element: <HomeContextProvider><Chats/></HomeContextProvider>
		}, {
			path: 'myPrompts',
			element: <HomeContextProvider><MyPrompts/></HomeContextProvider>
		}]
	}
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<RouterProvider router={router} fallbackElement={<p>Loading Narus app....</p>}/>
)