import {Box, Card, CardContent, IconButton, Stack, Tooltip, Typography} from '@mui/material'
import React, {useState} from 'react'
import {CardLabels} from '../cardLabel/CardLabel'
import {useChatsContext} from '../../context/ChatsContext'
import './ChatCards.scss'
import {useNavigate} from 'react-router-dom'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useFeedbackContext} from '../../context/FeedbackContext'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from '../promptForm/confirmationDialog/ConfirmationDialog'
import {EmptyChats} from '../emptySection/EmptyChats'

export const ChatCards = () => {

	const navigate = useNavigate()
	const {user} = useUser()
	const {loading, chatsByUser, deleteChat} = useChatsContext()
	const {showFeedback} = useFeedbackContext()

	const [showDeleteChatDialog, setShowDeleteChatDialog] = useState<boolean>(false)
	const [chatId, setChatId] = useState<string>()

	if (!loading && !chatsByUser.length) return <EmptyChats/>

	const onClickHandler = (chatId: string | undefined) => {
		if (!chatId) {
			showFeedback('Can\'t open that chat', 'Refresh and try it again', 'error')
			return
		}
		TrackActionEvent('Chat conversation', user?.id, {action: 'load', chat_id: chatId})
		navigate(`/aiChat/${chatId}`)
	}

	const deleteChatClickHandler = (event: React.MouseEvent, chatId: string | undefined) => {
		event.stopPropagation()
		TrackActionEvent('Delete chat', user?.id, {action: 'delete_icon_pressed'})
		setShowDeleteChatDialog(true)
		setChatId(chatId)
	}

	const deleteChatHandler = (event: React.MouseEvent) => {
		event.stopPropagation()
		if (!chatId) return
		setShowDeleteChatDialog(false)
		deleteChat(chatId).then(() => {
			TrackActionEvent('Delete chat', user?.id, {action: 'confirm_delete'})
			showFeedback('Success', 'The chat was successfully removed.', 'success')
		}).catch((error) => {
			showFeedback('Error', 'Something went wrong fulfilling the action.', 'error')
		})
	}

	const closeDialogHandler = (event: React.MouseEvent) => {
		event.stopPropagation()
		TrackActionEvent('Delete chat', user?.id, {action: 'cancel'})
		setShowDeleteChatDialog(false)
		setChatId(undefined)
	}

	return <Stack className='chatsCardsWrapper' useFlexGap spacing={{xs: 1, sm: 2, md: 4}}>
		{chatsByUser.map(chat => 
			<Card variant='outlined' key={chat.chatId} className='chatCard' onClick={() => onClickHandler(chat?.chatId)}>
				<CardContent>
					<Box className='chatTitleContainer'>
						<Typography variant='h4' className='chatTitle'>
							{chat.name}
						</Typography>
						<IconButton onClick={(event) => deleteChatClickHandler(event, chat.chatId)} className='deleteIcon'>
							<DeleteIcon/>
						</IconButton>
					</Box>
					<Tooltip title={chat.description} placement='bottom-end'>
						<Typography variant='body2' className='chatDescription'>
							{chat.description}
						</Typography>
					</Tooltip>
					<CardContent className='chatLabels'>
						<CardLabels labels={chat.labels} className='categoryLabel' maxElementsToShow={2}/>
					</CardContent>
				</CardContent>
			</Card>
		)}
		<ConfirmationDialog handleClose={(event) => closeDialogHandler(event)} open={showDeleteChatDialog}
		                    handleDelete={deleteChatHandler} currentEvent='chatDeletion'/>
	</Stack>
}