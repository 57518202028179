import React, {useEffect, useState} from 'react'
import './PromptsList.scss'
import SearchBar from '../../components/search/SearchBar'
import {Box, Grid, IconButton, InputAdornment, Modal, TextField, Typography} from '@mui/material'
import HeaderMenu from '../../components/headerMenu/HeaderMenu'
import {useUserContext} from '../../context/UserContext'
import {useUser} from '@clerk/clerk-react'
import {AnalyticsIdentify, GroupEvent, PageView,} from '../../service/SegmentService'
import CloseIcon from '@mui/icons-material/Close'
import {HomeTabs} from '../../components/homeTabs/HomeTabs'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import {AiModelSelector} from '../../components/aiModelSelector/AiModelSelector'
import {Sticky} from '../../components/Sticky/Sticky'
import {useSearchContext} from '../../context/SearchContext'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {useNavigate} from 'react-router-dom'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'

const PromptsList = () => {

	const {aiModels, loading: loadingAiModels} = useAiModelsContext()
	const {userInfo, loading: loadingUserInfo} = useUserInfoContext()
	const {setSearchText, clearSearch} = useSearchContext()
	const {initialAnalyticTracked, setInitialAnalyticTracked} = useUserContext()
	const {user} = useUser()

	const navigate = useNavigate()

	const [searchModalOpen, setSearchModalOpen] = useState(false)

	useEffect(() => {
		if (!initialAnalyticTracked) {
			setInitialAnalyticTracked(true)
			PageView('Narus')
			AnalyticsIdentify(user)
			GroupEvent(user?.id)
		}
	}, [user, initialAnalyticTracked, setInitialAnalyticTracked, aiModels, navigate, userInfo?.disabledAIModels])

	useEffect(() => {
		if (!loadingAiModels && !loadingUserInfo) {
			const enabledModels = aiModels?.filter(model => model.isEnabled)
			if (!enabledModels?.length || !enabledModels.some(model => isModelEnabledForUser(model.id, aiModels, userInfo?.disabledAIModels))) {
				navigate('/noModelsEnabled')
			}
		}
	}, [aiModels, navigate, userInfo, loadingUserInfo, loadingAiModels])

	const handleCloseSearchModal = () => {
		setSearchModalOpen(false)
		setSearchText('')
		clearSearch()
	}

	return <Grid container className='promptsListWrapper'>
		<Grid xs={12} item>
			<HeaderMenu/>
			<Box className='homeTitleContainer'>
				<Typography variant='h3'>Start your prompting journey</Typography>
				<Typography>Search, create and optimize prompts for higher quality outputs</Typography>
			</Box>
			<Sticky position='top' unstuckClasses='' stuckClasses='searchBarContainerStuck'>
				<TextField
					className='searchBar'
					onClick={() => setSearchModalOpen(true)}
					placeholder='Write your prompt here'
					InputProps={{
						startAdornment: <InputAdornment position='start'>
							<CommentIcon label='write prompt' primaryColor='#006D7A'/>
						</InputAdornment>,
					}}
				/>
				<AiModelSelector/>
			</Sticky>
			<HomeTabs/>
		</Grid>

		<Modal open={searchModalOpen} onClose={handleCloseSearchModal} className='searchModal'>
			<>
				<Box className='closeModalContainer'>
					<IconButton onClick={handleCloseSearchModal}>
						<CloseIcon/>
					</IconButton>
				</Box>
				<Box className='searchModalContainer'>
					<SearchBar/>
				</Box>
			</>
		</Modal>
	</Grid>
}

export default PromptsList