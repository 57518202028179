import {segmentId, narusAnalyticsAppName} from '../constants'
import type {UserResource} from '@clerk/types'
const {version} = require('../../package.json')

declare global {
    interface Window {
        analytics: any
    }
}

interface UserAnalyticData {
    userId: string
    currentLanguage: string
    email: string
    fullName: string
    createdAt: Date | string
    lastSignInAt: Date | string
}

export const LoadAnalytics = () => {
    window.analytics?.load(segmentId)
}

export const PageView = (category: string) => {
    window.analytics?.page(category, undefined, {
        app_name: narusAnalyticsAppName
    })
}

export const AnalyticsIdentify = (userData?: UserResource | null) => {
    if (!userData) return
    window.analytics?.identify(userData.id, buildUserData(userData))
}

export const GroupEvent = (userId?: string, traits: any = {}) => {
    window.analytics?.group(userId, traits)
}

export const TrackActionEvent = async (eventName: string, userId?: string, extraInfo?: any) => {
    window.analytics?.track(eventName, {
        userId,
        version: version || '',
        app_name: narusAnalyticsAppName,
        eventName,
        ...extraInfo
    })
}

export const TrackEvent = (props) => {
    window.analytics?.track(props['name'], props['data'])
}

const buildUserData = (user?: UserResource): UserAnalyticData => ({
    userId: user?.id || '',
    currentLanguage: navigator.language,
    fullName: user?.fullName || '',
    email: user?.emailAddresses[0].emailAddress || '',
    createdAt: user?.createdAt || '',
    lastSignInAt: user?.lastSignInAt || ''
})

export const getHomeTabForAnalytics = (tabValue: number): string => {
    switch (tabValue) {
        case 0:
            return 'my_recent_prompts'
        case 1:
            return 'favourites'
        default:
            return 'search'
    }
}

export const getAdminTabForAnalytics = (tabValue: number): string => {
    switch (tabValue) {
        case 0:
            return 'overview'
        case 1:
            return 'reports'
        default:
            return 'configuration'
    }
}