import React from 'react'
import {Box, Typography} from '@mui/material'
import Chip from '@mui/material/Chip'
import {useSearchContext} from '../../context/SearchContext'
import {AIModelID} from '../../types/AiModel'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'
import { AiModelSelectorProps } from '../../types/AiModelSelectorProps'

export const AiModelSelector = ({customModelsSelected, setCustomModelsSelected}: AiModelSelectorProps) => {

	const {aiModels} = useAiModelsContext()
	const {userInfo} = useUserInfoContext()
	const {modelsSelected, setModelsSelected} = useSearchContext()

	// const amazonQName: string | undefined = aiModels?.find(({id}) => id === AIModelID.AMAZON_Q)?.name

	const onClickModelHandler = (id: AIModelID) => {
		setCustomModelsSelected ?
			setCustomModelsSelected(prevState => ({...prevState, [id]: !prevState[id]})) :
			setModelsSelected(prevState => ({...prevState, [id]: !prevState[id]}))
	}

	return <Box>
		<Box className='aiModelContainer'>
			<Typography>Model:</Typography>
			{aiModels
				.filter(({id, name}) => id !== AIModelID.AMAZON_Q && isModelEnabledForUser(id, aiModels, userInfo?.disabledAIModels))
				.map(({id, name}) => <Chip label={name} className={`aiModelChip ${customModelsSelected ? customModelsSelected[id] && 'selected' : modelsSelected[id] && 'selected'}`} key={id}
				                      onClick={() => onClickModelHandler(id)}/>
				)}
		</Box>

		{/*{isModelEnabledForUser(AIModelID.AMAZON_Q, aiModels, userInfo?.disabledAIModels) ? <Box className='aiModelContainer confluence'>*/}
		{/*	<Typography>Internal knowledge:</Typography>*/}
		{/*	<Chip label={amazonQName} className={`aiModelChip ${modelsSelected[AIModelID.AMAZON_Q] && 'selected'}`}*/}
		{/*	      onClick={() => setModelsSelected(prevState => ({...prevState, [AIModelID.AMAZON_Q]: !prevState[AIModelID.AMAZON_Q]}))}/>*/}
		{/*</Box> : <></>}*/}
	</Box>
}