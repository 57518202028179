import React, {useEffect, useRef, useState} from 'react'
import {
	codeBlockPlugin,
	CodeMirrorEditor,
	codeMirrorPlugin,
	headingsPlugin,
	imagePlugin,
	linkDialogPlugin,
	linkPlugin,
	listsPlugin,
	markdownShortcutPlugin,
	MDXEditor,
	MDXEditorMethods,
	quotePlugin,
	tablePlugin, thematicBreakPlugin
} from '@mdxeditor/editor'
import {MarkdownEditorProps} from '../../types/MarkdownEditorProps'
import './MarkdownEditor.scss'
import {debouncedProcessLiElements} from '../../utils/htmlUtils'
import {usePromptCreationContext} from '../../context/PromptCreationContext'

export const MarkdownEditor = ({onChangeHandler, aiText, loadingText, isLoading, label, placeholderValue, isUser}: MarkdownEditorProps) => {

	const markdownEditorRef = useRef<MDXEditorMethods | null>(null)

	const [loadingTextWithEllipsis, setLoadingTextWithEllipsis] = useState<string>(loadingText)
	const [isFormatAppliedToOutput, setIsFormatAppliedToOutput] = useState<boolean>(false)

	const {aiOutputLoading} = usePromptCreationContext()

	useEffect(() => {
		markdownEditorRef.current?.setMarkdown(isLoading && !aiText ? loadingTextWithEllipsis : aiText ?? '')
		const intervalID = setInterval(() => setLoadingTextWithEllipsis(previousText => previousText.endsWith('...') ? loadingText : (previousText + '.')), 600)
		if (!isLoading) clearInterval(intervalID)
		return () => clearInterval(intervalID)
	}, [aiText, isLoading, loadingText, loadingTextWithEllipsis])

	useEffect(() => {
		if (!aiOutputLoading && !isFormatAppliedToOutput) {
			debouncedProcessLiElements()
			setIsFormatAppliedToOutput(true)
		}
	}, [aiOutputLoading, isFormatAppliedToOutput])

	return <MDXEditor
		ref={markdownEditorRef}
		onChange={onChangeHandler}
		className={`markdownEditor ${isUser ? 'userMessageListNone' : ''}`}
		onError={error => console.error(error)}
		markdown={aiText}
		readOnly={label === 'message'}
		placeholder={placeholderValue || ''}
		suppressHtmlProcessing={true}
		plugins={[
			headingsPlugin(),
			listsPlugin(),
			linkPlugin(),
			linkDialogPlugin(),
			quotePlugin(),
			imagePlugin(),
			tablePlugin(),
			thematicBreakPlugin(),
			codeBlockPlugin({
				defaultCodeBlockLanguage: 'js',
				codeBlockEditorDescriptors: [
					{
						priority: 100,
						match: () => true,
						Editor: CodeMirrorEditor
					}]
			}),
			codeMirrorPlugin({
				codeBlockLanguages: {js: 'JavaScript', css: 'CSS', html: 'HTML'},
			}),
			markdownShortcutPlugin(),
		]}
	/>
}