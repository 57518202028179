import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material'
import React from 'react'
import {DonutChart} from '@tremor/react'
import './AiUsageChart.scss'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import {UserUsageStatistic} from '../../../types/UserUsageStatistic'
import './tailwind_out.css'
import Spinner from '../../spinner/Spinner'
import {NoDataAvailable} from './NoDataAvailable'

export const AiUsageChart = () => {

	const {usageStatistics, top5UsersStatistics, totalPromptsByModel, loading} = useStatisticsContext()

	return <Box className='aiChartContainer'>
		<Typography className='aiChartTitle' variant='h3'>User activity by AI Model</Typography>
		<Grid container>
			{loading && <Grid item xs={12} className='spinnerContainer'><Spinner/></Grid>}
			{!loading && !usageStatistics?.userUsageStatistics?.length && <NoDataAvailable/>}
			{!loading && usageStatistics?.userUsageStatistics && usageStatistics.userUsageStatistics.length > 0 && <>
                <Grid item xs={4}>
                    <DonutChart
                        data={totalPromptsByModel}
                        variant='donut'
                        noDataText='No data available yet'
                        colors={[
	                        '#D1BAFD', //ChatGPT3-5
	                        '#B5E2E8', //ChatGPT4
	                        '#A6BCFF', //Gemini
	                        '#FFB0D0', //Llama
	                        '#F3C6B5', //Mistral
	                        '#575757', //Amazon-Q
	                        '#F0D1B0', //Claude
                        ]}
                        valueFormatter={(value: number) => `${value} prompts`}
                    />
                </Grid>
				<Grid item xs={1}/>
                <Grid item xs={7}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow className='topUserTableHeader'>
                                <TableCell>Top 5 most active users</TableCell>
                                <TableCell align="right">Prompts sent</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
							{top5UsersStatistics.map((userUsageStatistic: UserUsageStatistic) => <TableRow key={`row-user${userUsageStatistic.userId}`} className='topUserTableRow'>
									<TableCell>{userUsageStatistic.userFullName}</TableCell>
									<TableCell align="right">{userUsageStatistic.totalPrompts}</TableCell>
								</TableRow>
							)}
                        </TableBody>
                    </Table>
                </Grid>
            </>
			}
		</Grid>
	</Box>
}