import {Box, Button, Grid, TextField, Typography} from '@mui/material'
import {AdminEmail} from './AdminEmail'
import React, {useState} from 'react'
import {useAdminEmailsContext} from '../../../../context/AdminEmailsContext'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {isEmailAddress} from '../../../../utils/accountCreationHelpers'
import {useUser} from '@clerk/clerk-react'
import {useOrganizationContext} from '../../../../context/OrganizationContext'
import {useFeedbackContext} from '../../../../context/FeedbackContext'

export const Admins = () => {

	const {user} = useUser()
	const {organization} = useOrganizationContext()
	const {adminEmails, editAdminEmails} = useAdminEmailsContext()
	const {showFeedback} = useFeedbackContext()

	const [addingNewAdmin, setAddingNewAdmin] = useState<boolean>(false)
	const [adminValidError, setAdminValidError] = useState<boolean>(false)
	const [newAdminEmail, setNewAdminEmail] = useState<string>('')
	const [validationError, setValidationError] = useState<string>('')

	const handleDeleteAdminEmailClicked = async (emailAddress: string) => {
		if (adminEmails.length <= 1) return

		const emails = adminEmails.map(adminEmail => adminEmail.email).filter(adminEmail => adminEmail !== emailAddress)
		const error = await editAdminEmails(emails)

		if (!error) {
			TrackActionEvent('Organization settings', user?.id, {
				action: 'removeAdmin',
				organizationId: organization?.hashKey,
				organization_domains_count: organization?.allowedDomains?.length ?? 0
			})
			showFeedback('Admin removed', '', 'success', 5)
		}
	}

	const handleCancelSaveAdmin = () => {
		setAddingNewAdmin(false)
		setValidationError('')
		setAdminValidError(false)
	}

	const handleSaveAdmin = async () => {
		if (!isEmailAddress(newAdminEmail)) {
			setAdminValidError(true)
			setValidationError('Your input doesn\'t match an email format. Try a format like narus@kolekti.com')
			return
		}

		if (adminEmails.find(adminEmail => adminEmail.email === newAdminEmail)) {
			setAdminValidError(true)
			setValidationError('That email already exists. Try adding a different one')
			return
		}
		setAdminValidError(false)
		const error = await editAdminEmails([...adminEmails.map(adminEmail => adminEmail.email), newAdminEmail])
		if (error) {
			setAdminValidError(true)
			setValidationError(error)
			return
		}

		showFeedback('Admin created', '', 'success', 5)
		TrackActionEvent('Organization settings', user?.id, {
			action: 'addAdmin',
			organizationId: organization?.hashKey,
			organization_domains_count: organization?.allowedDomains.length,
			admins_count: adminEmails.length
		})
		setNewAdminEmail('')
		setAddingNewAdmin(false)
	}

	return <Grid container className='settingContainer'>
		<Grid item xs={12}>
			<Typography variant='h4' className='sectionTitle'>Admin permissions</Typography>
		</Grid>
		<Box className='sectionContainer'>
			<Grid item xs={12}>
				<Typography className='body'>Give admin permissions to a user by entering their email. The user must already have access
					before
					receiving admin permission</Typography>
			</Grid>
			<Grid item xs={12} className='domainNameContainer'>
				{adminEmails.map((adminEmail, index) =>
					<AdminEmail email={adminEmail.email} key={`admin-email${index}`} deleteDisabled={adminEmails.length === 1}
					            handleDeleteClicked={handleDeleteAdminEmailClicked}/>)
				}
			</Grid>
		</Box>
		{addingNewAdmin ? <Grid container className='settingContainer'>
			<Grid item xs={12}>
				<Typography variant='h5' className='subsubtitle'>New Admin</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField variant='outlined' label='Email' placeholder='narus@kolekti.com' fullWidth className='newDomainTextfield' error={adminValidError}
				           helperText={adminValidError ? validationError : ''}
				           value={newAdminEmail}
				           onChange={(event) => setNewAdminEmail(event.target.value)}/>
			</Grid>
			<Grid item xs={12}>
				<Button variant='contained' onClick={handleSaveAdmin} className='saveButton'>Add Admin</Button>
				<Button variant='outlined' onClick={handleCancelSaveAdmin} className='cancelButton'>Cancel</Button>
			</Grid>
		</Grid> : <Grid container className='settingContainer'>
			<Grid item xs={12}>
				<Button onClick={() => setAddingNewAdmin(true)} className='addDomainButton'>+ Add Admins</Button>
			</Grid>
		</Grid>
		}
	</Grid>
}