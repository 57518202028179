import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Drawer, Grid, TextField, Typography} from '@mui/material'
import {emailMatchesDomain, isEmailAddress, isEmailDomain, isWhitelistedEmail} from '../../../../utils/accountCreationHelpers'
import {AccountDomain} from './AccountDomain'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, {useState} from 'react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useOrganizationContext} from '../../../../context/OrganizationContext'
import {EditOrganizationDomain} from './EditOrganizationDomain'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'

export const UserAccess = () => {

	const {user} = useUser()
	const {organization, editOrganization} = useOrganizationContext()
	const {displayDrawerEditOrganizationDomain, toggleDrawer} = useToggleDrawerContext()

	const [addingNewDomain, setAddingNewDomain] = useState<boolean>(false)
	const [domainValidError, setDomainValidError] = useState<boolean>(false)
	const [domainEmailValidError, setDomainEmailValidError] = useState<boolean>(false)
	const [newDomain, setNewDomain] = useState<string>('')
	const [newDomainEmail, setNewDomainEmail] = useState<string>('')
	const [domainToEdit, setDomainToEdit] = useState<string>('')
	const [validationError, setValidationError] = useState<string>('')

	const handleCancelSaveDomain = () => {
		setAddingNewDomain(false)
		setValidationError('')
		setDomainValidError(false)
	}

	const handleDeleteDomainClicked = async (domain: string) => {
		if (deleteDisabled || (user?.emailAddresses.length && emailMatchesDomain(user?.emailAddresses[0].emailAddress)(domain))) return
		const error = await editOrganization(organization?.name ?? '', organization?.allowedDomains?.filter(allowedDomain => allowedDomain !== domain) ?? [])
		if (!error) TrackActionEvent('Organization settings', user?.id, {
			action: 'removeDomain',
			organizationId: organization?.hashKey,
			organization_domains_count: organization?.allowedDomains?.length ?? 0
		})
	}

	const handleSaveEmailDomain = async () => {
		if (!isEmailAddress(newDomainEmail)) {
			setDomainEmailValidError(true)
			setValidationError('Your input doesn\'t match an email format. Try a format like narus@kolekti.com')
			return
		}

		if (organization?.allowedDomains.includes(newDomainEmail)) {
			setDomainEmailValidError(true)
			setValidationError('That email already exists. Try adding a different one')
			return
		}
		setDomainEmailValidError(false)
		const allowedDomains = [...(organization?.allowedDomains ?? []), newDomainEmail]
		const error = await editOrganization(organization?.name ?? '', allowedDomains)
		if (error) {
			setDomainEmailValidError(true)
			setValidationError(error)
			return
		}

		TrackActionEvent('Organization settings', user?.id, {action: 'addUserEmail', organizationId: organization?.hashKey, organization_domains_count: allowedDomains.length})
		setNewDomainEmail('')
	}

	const handleSaveDomain = async () => {
		if (!isEmailDomain(newDomain)) {
			setDomainValidError(true)
			setValidationError('Admin email has to follow the example *@acme.com')
			return
		}
		if (isWhitelistedEmail(newDomain)) {
			setDomainValidError(true)
			setValidationError('Generic domains are not accepted')
			return
		}
		if (organization?.allowedDomains.includes(newDomain)) {
			setDomainValidError(true)
			setValidationError('That domain already exists. Try adding a different one')
			return
		}
		setDomainValidError(false)
		const allowedDomains = [...(organization?.allowedDomains ?? []), newDomain]
		const error = await editOrganization(organization?.name ?? '', allowedDomains)
		if (error) {
			setDomainValidError(true)
			setValidationError(error)
			return
		}

		TrackActionEvent('Organization settings', user?.id, {action: 'addDomain', organizationId: organization?.hashKey, organization_domains_count: allowedDomains.length})
		setNewDomain('')
		setAddingNewDomain(false)
	}

	const handleEditClicked = (event: React.MouseEvent<HTMLButtonElement>, domain: string) => {
		setDomainToEdit(domain)
		return toggleDrawer(true, 'EDIT_ORGANIZATION_DOMAIN_DRAWER')(event)
	}

	const deleteDisabled = organization?.allowedDomains?.length !== undefined && organization?.allowedDomains?.length <= 1
	const allowedDomainEmails = organization?.allowedDomains.filter(isEmailAddress)


	return <>
		<Grid container className='settingContainer'>
		<Grid item xs={12}>
			<Typography variant='h4' className='sectionTitle'>User access</Typography>
		</Grid>
		<Box className='sectionContainer'>
			<Grid item xs={12}>
				<Typography variant='h5' className='subtitle'>Add by domain</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className='body'>You can give bulk access by adding an email domain. This allows all users with matching email addresses to access your
					workspace</Typography>
			</Grid>
			<Grid className='domainNameContainer'>
				{organization?.allowedDomains
					.filter(isEmailDomain)
					.map((allowedDomain, index) =>
						<AccountDomain domain={allowedDomain} key={`allowed-domain${index}`} deleteDisabled={deleteDisabled} isEditAllowed={true}
						               handleDeleteClicked={handleDeleteDomainClicked} handleEditClicked={handleEditClicked} deleteEventName='domainDeletion'/>)
				}
			</Grid>
		</Box>
		{addingNewDomain ? <Grid container className='settingContainer'>
				<Grid item xs={12}>
					<Typography variant='h5' className='subsubtitle'>New Domain</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField variant='outlined' label='Domain' placeholder='@adaptavist.com' fullWidth className='newDomainTextfield' error={domainValidError}
					           helperText={domainValidError ? validationError : ''}
					           onChange={(event) => setNewDomain(event.target.value)}/>
				</Grid>
				<Grid item xs={12}>
					<Button variant='contained' onClick={handleSaveDomain} className='saveButton'>Add domain</Button>
					<Button variant='outlined' onClick={handleCancelSaveDomain} className='cancelButton'>Cancel</Button>
				</Grid>
			</Grid> :
			<Grid container className='settingContainer'>
				<Grid item xs={12}>
					<Button onClick={() => setAddingNewDomain(true)} className='addDomainButton'>+ Add domain</Button>
				</Grid>
			</Grid>
		}

		<Box className='sectionContainer'>
			<Grid item xs={12}>
				<Typography variant='h5' className='subtitle'>Add by email</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className='body'>Add users by entering their email addresses.</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField variant='outlined' label='User email' placeholder='john@acme.com' fullWidth className='newUserInput' value={newDomainEmail}
				           error={domainEmailValidError}
				           helperText={domainEmailValidError ? validationError : ''}
				           onChange={(event) => setNewDomainEmail(event.target.value)} InputProps={{
					endAdornment: <Button color="primary" className='addUserButton' onClick={handleSaveEmailDomain}>Add user</Button>
				}}/>
			</Grid>
		</Box>
		{allowedDomainEmails?.length ? <Grid item xs={12}>
				<Accordion defaultExpanded={true} className='domainUsersAccordion'>
					<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
						<Typography variant='h5' className='subtitle'>Users manually entered</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box className='sectionContainer'>
							{allowedDomainEmails.map((allowedEmail, index) =>
								<AccountDomain domain={allowedEmail} key={`allowed-email${index}`} deleteDisabled={deleteDisabled} isEditAllowed={false}
								               handleDeleteClicked={handleDeleteDomainClicked} deleteEventName='domainEmailDeletion'/>)
							}
						</Box>
					</AccordionDetails>
				</Accordion>
			</Grid>
			: ''}
	</Grid>
		<Drawer anchor='right' open={displayDrawerEditOrganizationDomain} onClose={toggleDrawer(false, 'EDIT_ORGANIZATION_DOMAIN_DRAWER')}>
			{domainToEdit ? <EditOrganizationDomain domain={domainToEdit}/> : <></>}
		</Drawer>
	</>
}