import {Box, Button, Grid, TextField, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useOrganizationContext} from '../../../../context/OrganizationContext'
import './GlobalSettings.scss'
import {isCompanyNameValid} from '../../../../utils/accountCreationHelpers'
import {ReactComponent as AdaptavistIcon} from '../../../../images/adaptavist.svg'
import {useUser} from '@clerk/clerk-react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {Admins} from './Admins'
import {UserAccess} from './UserAccess'

export const GlobalSettings = () => {

	const {user} = useUser()
	const {organization, editOrganization} = useOrganizationContext()

	const [nameValidError, setNameValidError] = useState<boolean>(false)
	const [organizationName, setOrganizationName] = useState<string>(organization?.name ?? '')
	const [validationError, setValidationError] = useState<string>('')

	useEffect(() => setOrganizationName(organization?.name ?? ''), [organization])

	const handleSaveOrganizationName = async () => {
		if (!isCompanyNameValid(organizationName)) {
			setNameValidError(true)
			setValidationError('Only alphanumeric, and maximum length of 220 characters')
			return
		}
		setNameValidError(false)
		const error = await editOrganization(organizationName, organization?.allowedDomains ?? [])
		if (error) {
			setValidationError(error)
			setNameValidError(true)
			return
		}
		TrackActionEvent('Organization settings', user?.id, {
			action: 'updateOrganizationName',
			organizationId: organization?.hashKey,
			organization_domains_count: organization?.allowedDomains?.length ?? 0
		})
	}

	return <Grid container className='globalSettings'>
		<Grid item xs={12}>
			<Typography variant='h3' className='title'>Global settings</Typography>
			<Grid container className='settingContainer'>
				<Grid item xs={12}>
					<Typography variant='h4' className='sectionTitle'>Company Information</Typography>
				</Grid>
				<Grid item xs={12} className='organizationIconWrapper'>
					<Box className='organizationIconContainer'>
						<AdaptavistIcon/>
					</Box>
					<Box className='organizationNameContainer'>
						<TextField label="Company name" value={organizationName} InputLabelProps={{shrink: true}} fullWidth
						           onChange={(event) => setOrganizationName(event.target.value)}
						           error={nameValidError}
						           helperText={nameValidError ? validationError || validationError : ''}/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Button variant='contained' className='saveButton' onClick={handleSaveOrganizationName}>Save</Button>
				</Grid>
			</Grid>

			<UserAccess />
			<Admins/>
		</Grid>
	</Grid>
}