import {FC, ReactElement} from 'react'
import {Box, Button, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import './EmptySection.scss'

type EmptySectionProps = {
    icon?: ReactElement
    title: string
    description: string
}

export const EmptySection: FC<EmptySectionProps> = ({
    icon,
    title,
    description
}) => {
    const navigate = useNavigate()

    return <Box className='EmptySection_Container'>
        { icon ?? <></> }
        <Box className='EmptySection_Text'>
            <Typography className='EmptySection_Title'>{title}</Typography>
            <Typography className='EmptySection_Description'>{description}</Typography>
        </Box>
        <Button variant='contained' onClick={() => navigate('/')}>Start a new chat</Button>
    </Box>
}