import {Tab, Tabs} from '@mui/material'
import React from 'react'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {usePromptsContext} from '../../context/PromptsContext'
import {useUserContext} from '../../context/UserContext'
import {getHomeTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import './homeTabs.scss'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

export const CustomTabPanel = (props: TabPanelProps) => {
	const {children, value, index, ...other} = props

	return <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}
				className='tabPanelContainer'
	            aria-labelledby={`tab-${index}`}{...other}>
		{value === index && children}
	</div>
}

export const HomeTabs = () => {

	const {favourites, recentlyUsedPrompts} = usePromptsContext()
	const {selectedTab, setSelectedTab} = useUserContext()
	const {user} = useUser()

	const selectTabHandler = (newValue: number) => {
		setSelectedTab(newValue)
		TrackActionEvent('Select tab', user?.id, {section: getHomeTabForAnalytics(newValue)})
	}

	return <>
		<Tabs value={selectedTab}
		      onChange={(_: React.SyntheticEvent, newValue: number) => selectTabHandler(newValue)}
		      className='promptTabs'>
			<Tab label="My recent prompts"/>
			<Tab label="Favourites"/>
		</Tabs>
		<CustomTabPanel value={selectedTab} index={0}>
			<PromptsCards prompts={recentlyUsedPrompts} showLoadMore={false}
			              noPromptsMessage='There are no prompts in this library yet'/>
		</CustomTabPanel>
		<CustomTabPanel value={selectedTab} index={1}>
			<PromptsCards prompts={favourites} showLoadMore={false}
			              noPromptsMessage='There are no prompts in this library yet'/>
		</CustomTabPanel>
	</>
}