export const splitTextAtByteLimit = (text: string, byteLimit: number): string => {
    if (new Blob([text]).size <= byteLimit) return text

    let currentIndex = 0
    let updatedText = ''

    while (currentIndex < text.length) {
        const char = text.charAt(currentIndex)
        const newByteSize = new Blob([updatedText + char]).size

        if (newByteSize > byteLimit) break

        updatedText += char
        currentIndex++
    }

    return updatedText
}
