import { AIModelID } from './AiModel'
import { AsyncState } from './AsyncState'

export type FormTemplate = {
    companyName: string,
    companyDomains: string[],
    adminEmails: string[],
    models: { modelId: AIModelID, name: string, apiKey: string }[]
}

export type FormMap<T> = { [key in keyof FormTemplate]: T }

export type ChangeValueCallback = <T extends keyof FormTemplate>(prop: T, value: FormTemplate[T]) => void
export type ChangeValidCallback = (prop: keyof FormTemplate, value: boolean | undefined) => void
export type ChangeErrorCallback = (prop: keyof FormTemplate, value: string | undefined) => void

export type ContextValue = {
    form: FormTemplate,
    stepsState: StepsState,
    valid: FormMap<boolean | undefined>,
    error: FormMap<string | undefined>,
    changeFormValue: ChangeValueCallback,
    changeFormValueValid: ChangeValidCallback,
    changeFormValueError: ChangeErrorCallback,
    resetStepState: (index: StepIndex) => void
    submitStep: (index: StepIndex) => void
}

export const StepIndexes = [0, 1, 2] as const

export type StepIndex = typeof StepIndexes[number]

export type StepsState = { [key in StepIndex]: AsyncState }

export type Config = {
    [key in StepIndex]: {
        title: string,
        formTitle: string,
        actionTitle: string,
        page: string,
        controls: (keyof FormTemplate)[]
        validators: (keyof FormTemplate)[]
    }
}
