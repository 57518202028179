import {FC} from 'react'
import {Grid} from '@mui/material'
import {UserAuditLog} from '../../../types/UserAuditLog'
import {AuditingLogTrail} from './AuditingLogTrail'
import {AuditingLogDetailHeader} from './AuditingLogDetailHeader'
import {AuditingLogDetailInfo} from './AuditingLogDetailInfo'
import {AuditingLogDetailList} from './AuditingLogDetailList'
import './AuditingLogDetail.scss'

type AuditingLogDetailProps = {
	auditingLog: UserAuditLog
	goBack: () => void
}

export const AuditingLogDetail: FC<AuditingLogDetailProps> = ({
	auditingLog, 
	goBack
}) => <>
	<Grid item xs={12} className='auditingLogDetailContainer'>
		<AuditingLogDetailHeader onBack={goBack} />
	</Grid>
	<Grid item xs={12} className='auditingLogDetailContainer auditDataRow' container>
		<AuditingLogDetailInfo auditLog={auditingLog} />
	</Grid>
	<Grid item xs={12} className='auditingLogsContentContainer'>
		<Grid className='auditingLogDetailContainer auditLogsContainer'>
			<AuditingLogDetailList auditLog={auditingLog} />
		</Grid>
		<Grid className='auditingLogDetailContainer auditTrailContainer'>
			<AuditingLogTrail auditLog={auditingLog}/>
		</Grid>
	</Grid>
</>
