import React, {useState} from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from "@mui/material/Button"
import './IntegrationFeature.scss'
import {validateApiKeyFormat} from '../../helpers/apiKeyHelpers'
import {OpenAIApiKey} from '../../types/AiModel'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {useAiModelsContext} from '../../context/AIModelsContext'

const IntegrationFeature = () => {

	const [apiKeyValue, setApiKeyValue] = useState<OpenAIApiKey>('')
	const [error, setError] = useState<boolean>(false)

	const {showFeedback} = useFeedbackContext()
	const {updateAiModelConfig} = useAiModelsContext()

	const saveApiKeyHandler = () => {
		if (!apiKeyValue) return

		updateAiModelConfig(apiKeyValue, 'model-name',false, 'model-id')
			.then(() => showFeedback('Success', 'API key saved successfully', 'success', 10))
			.catch(() => showFeedback('Error', 'The API key couldn’t be saved, please try again.', 'error', 10))
	}

	const apiKeyOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const apiKey = event.target.value
		setApiKeyValue(apiKey)
		setError(apiKey.length === 0 || !validateApiKeyFormat(apiKey))
	}

	return (
		<>
			<div className='textFieldWrapper'>
				<Box component='form' noValidate autoComplete='off'>
					<TextField id='filled-basic'
					           value={apiKeyValue}
					           onChange={apiKeyOnChangeHandler}
					           error={error}
					           label='Enter API key...'
					           variant='filled'
					           className='textFieldInput textFieldApiKey'
					           helperText={error ? 'API key not valid' : ''}/>
				</Box>
				<Button disabled={error}
				        onClick={saveApiKeyHandler}
				        variant='contained'
				        size='large'
				        className='button saveButton'>Save</Button>
			</div>
		</>
	)
}

export default IntegrationFeature