import {Box, Button, Typography} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { FC } from 'react'
import { StepIndex } from '../../types/AccountCreation'
import './AccountCreationError.scss'

type AccountCreationErrorProps = {
    index: StepIndex
}

export const AccountCreationError: FC<AccountCreationErrorProps> = ({
    index
}) => {
    const { resetStepState } = useAccountCreationContext()

    const handleButtonClicked = () => {
        resetStepState(index)
    }

	return <Box className='AccountCreationError'>
        <Box className='AccountCreationError_Icon'>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id='linearColors' x1='15.5' y1='0.331055' x2='15.5' y2='20.6689' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#6BC4D0' stopOpacity='0.3'/>
                        <stop offset='1' stopColor='#C8C4D0' stopOpacity='0.5'/>
                    </linearGradient>
                </defs>
            </svg>
            <ErrorIcon sx={{fontSize: 67, fill: 'url(#linearColors)'}}/>
        </Box>
        <Box className='AccountCreationError_Content'>
            <Typography variant='h3' className='AccountCreationError_Title'>Sorry, we were unable to synchronize the data.</Typography>
            <Typography variant='h5' className='AccountCreationError_Subtitle'>We had a problem connecting to the database, please reload the page and enter the data again.</Typography>
            <Button variant='contained' onClick={handleButtonClicked}>Reload</Button>
        </Box>
    </Box>
}