import {Box, Button, Typography} from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircle'
import { FC } from 'react'
import './AccountCreationCompleted.scss'
import { useNavigate } from 'react-router-dom'

export const AccountCreationCompleted: FC = () => {
    const navigate = useNavigate()

    const handleButtonClicked = () => {
        navigate('/')
    }

	return <Box className='AccountCreationCompleted'>
        <Box className='AccountCreationCompleted_Icon'>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id='linearColors' x1='15.5' y1='0.331055' x2='15.5' y2='20.6689' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#6BC4D0' stopOpacity='0.3'/>
                        <stop offset='1' stopColor='#C8C4D0' stopOpacity='0.5'/>
                    </linearGradient>
                </defs>
            </svg>
            <CheckIcon sx={{fontSize: 166, fill: 'url(#linearColors)'}}/>
        </Box>
        <Box className='AccountCreationCompleted_Content'>
            <Typography variant='h3' className='AccountCreationCompleted_Title'>Welcome to Narus</Typography>
            <Typography variant='h5' className='AccountCreationCompleted_Subtitle'>The workspace has been successfully created, you can now access narus.</Typography>
            <Button variant='contained' onClick={handleButtonClicked}>Go to narus</Button>
        </Box>
    </Box>
}