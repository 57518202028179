import {useUserContext} from '../context/UserContext'
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {getAuditingLogs} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import {UserAuditLog} from '../types/UserAuditLog'
import {DateRange} from 'rsuite/DateRangePicker'
import {convertToStartOfDay} from '../helpers/DateHelpers'

type AuditingLogsValue = {
	loading: boolean
	auditingLogs: UserAuditLog[]
	setDateRangeFilter: Dispatch<SetStateAction<DateRange | undefined>>
}

export const useAuditingLogs = (): AuditingLogsValue => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [auditingLogs, setAuditingLogs] = useState<UserAuditLog[]>([])
	const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

	const getUsersAuditingLogs = useCallback((dateRangeFilter: DateRange | undefined) => {
		setLoading(true)
		getAuditingLogs(token, dateRangeFilter ? [convertToStartOfDay(dateRangeFilter[0]), dateRangeFilter[1]] : undefined)
			.then((logs: UserAuditLog[]) => {
			setAuditingLogs(logs)
		}).catch(error => {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
			setAuditingLogs([])
		}).finally(() => setLoading(false))

	}, [token, showFeedback])


	useEffect(() => {
		getUsersAuditingLogs(dateRangeFilter)
	}, [getUsersAuditingLogs, dateRangeFilter])

	return {
		loading,
		auditingLogs,
		setDateRangeFilter
	}
}