
export const stringAvatar = (name: string) => {
	return {
		sx: {
			width: 20,
			height: 20,
			fontSize: '12px'
		},
		alt: name,
		children: `${name.split(' ')[0][0]}${name.split(' ')[1][0] ?? ''}`,
	}
}