import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import './HoverMenuOutput.scss'
import RetryIcon from '@atlaskit/icon/glyph/retry'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import {IconButton, Stack} from '@mui/material'
import {HoverMenuOutputProps} from '../../types/HoverMenuOutputProps'

export const HoverMenuOutput = ({onRegenerateOutput, regeneratedOutputs, onCopyOutput, isLastMessage, isBot}: HoverMenuOutputProps) => {

    return (
        <Box className='hoverMenuContainer'>
            <Stack direction='row' justifyContent='space-around' className='iconButtonsContainer'>
                <Tooltip title='Copy' placement='top' className='hoverMenuTooltip'>
                    <IconButton onClick={onCopyOutput}><CopyIcon label='copy output'/></IconButton>
                </Tooltip>
                {isLastMessage && isBot? <Tooltip title='Regenerate' placement='top' className='hoverMenuTooltip'>
                    <span>
                    <IconButton disabled={regeneratedOutputs ? regeneratedOutputs.length >= 4 : false}
                                onClick={onRegenerateOutput}><RetryIcon label='regenerate output'/></IconButton>
                </span>
                </Tooltip> : <></>}
            </Stack>
        </Box>
    )
}