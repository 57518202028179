import { Prompt, UserPromptTemplate } from '../types/Prompt'

export const createPromptObject = (promptTemplate: UserPromptTemplate): Prompt => {
	const {title, description} = promptTemplate
    
	return {
		...promptTemplate,
		title: title.trim(),
		description: description.trim(),
		creationDate: new Date().getTime(),
	}
}