import { FC, useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ACCOUNT_CREATION_COMPONENT_MAP, ACCOUNT_CREATION_CONFIG } from '../../constants/AccountCreationConstants'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { StepIndex } from '../../types/AccountCreation'
import './AccountCreationForm.scss'

type AccountCreationFormProps = {
    index: StepIndex
}

export const AccountCreationForm: FC<AccountCreationFormProps> = ({
    index
}) => {
    const { valid, stepsState, submitStep } = useAccountCreationContext()

    const { actionTitle, controls, formTitle, validators } = ACCOUNT_CREATION_CONFIG[index]
    const state = stepsState[index]
    const loading = state === 'loading'

    const actionButtonText = loading ? 'in progress' : actionTitle
    const actionButtonDisabled = !validators.every(key => valid[key]) || state === 'loading'

    const controlComponents = controls.map(value => {
        const Component = ACCOUNT_CREATION_COMPONENT_MAP[value]
        return <Component key={value}/>
    })

    const handleActionButtonClicked = useCallback(() => {
        submitStep(index)
    }, [index, submitStep])

    return <Box className='AccountCreationForm'>
        <Typography variant='h3'>{formTitle}</Typography>
        <Box className='AccountCreationForm_Controls'>
            { controlComponents }
        </Box>
        <Box className='AccountCreationForm_Action'>
            <Button variant='contained' disabled={actionButtonDisabled} onClick={handleActionButtonClicked}>
                { actionButtonText }
            </Button>
        </Box>
    </Box>
}