import * as React from 'react'
import {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Box, Button, Drawer, InputAdornment, TextField} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import './Chat.scss'
import {AiAdvancedSettings} from '../aiAdvancedSettings/AiAdvancedSettings'
import {ChatProps} from '../../types/ChatProps'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {Message} from './Message'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {ChatMessage} from '../../types/ChatMessage'
import {MessageLoading} from './MessageLoading'
import SaveChatCompilation from '../saveChatCompilation/SaveChatCompilation'
import {ReactComponent as MagicWandIcon} from '../../images/magic-wand.svg'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'
import SaveChatHistory from '../saveChatHistory/SaveChatHistory'

export const Chat = ({promptChatDetails, generateAiOutputHandler, tempPromptId, optimizedPromptHandler}: ChatProps) => {
	const {modelId} = promptChatDetails

	const {
		userPrompt, aiPrompt, promptChats, setUnsavedChanges, isOptimizedPrompt,
		aiPromptLoading, aiOutputLoading, setAiPrompt, setUserPrompt, setPromptChats, setAiOutputLoading
	} = usePromptCreationContext()
	const {displayDrawerSaveChatCompilation, displayDrawerSaveChatHistory, toggleDrawer} = useToggleDrawerContext()


	const [messages, setMessages] = useState<ChatMessage[]>(promptChats.find(promptChat => promptChat.modelId === modelId)?.messages || [])
	const [expandPromptOptimization, setExpandPromptOptimization] = useState<boolean>(false)
	const [botMessageCount, setBotMessageCount] = useState<number>(0)
	const [userHasScrolledUp, setUserHasScrolledUp] = useState<boolean>(false)
	const [chatMessageLoading, setChatMessageLoading] = useState<boolean>(aiOutputLoading)
	const [regenerateChatMessageLoading, setRegenerateChatMessageLoading] = useState<boolean>(false)
	const messagesEndRef = useRef<HTMLDivElement>(null)
	const autoScroll = useRef<boolean>(false)
	const [isPromptOptimizationChat, setIsPromptOptimizationChat] = useState<boolean>(false)


	useEffect(() => {
		if (!chatMessageLoading && aiOutputLoading && messages.length % 2 !== 0) setChatMessageLoading(true) // odd messages means user has sent a new message
	}, [aiOutputLoading, chatMessageLoading, messages.length])

	useEffect(() => {
		const messagesForCurrentModel = promptChats.find(promptChat => promptChat.modelId === modelId)?.messages || []
		setMessages(messagesForCurrentModel)
		const newBotMessagesCount = messagesForCurrentModel.filter(message => message.sender === 'bot').length

		if (messagesForCurrentModel.some(message => message.loading) || newBotMessagesCount > botMessageCount) {
			setChatMessageLoading(false)
		}

		if (!userHasScrolledUp) {
			autoScroll.current = true
			messagesEndRef.current?.scrollIntoView(false)
		}
		setBotMessageCount(newBotMessagesCount)

	}, [promptChats, modelId, chatMessageLoading, botMessageCount, aiOutputLoading, messages, userHasScrolledUp])


	const handleSend = () => {
		if (userPrompt.trim() !== '') {
			setUserHasScrolledUp(false)
			if (isOptimizedPrompt()) {
				optimizedPromptHandler(modelId)
			} else {
				setPromptChats((previousValue: PromptChatDetail[]): PromptChatDetail[] => {
					const chatIndex = previousValue.findIndex(previousChat => previousChat.modelId === modelId)
					if (chatIndex === -1) return previousValue

					const updatedMessages: ChatMessage[] = [
						...previousValue[chatIndex].messages,
						{sender: 'user', text: userPrompt, optimized: false}
					]

					const updatedChat: PromptChatDetail = {
						...previousValue[chatIndex],
						messages: updatedMessages,
						compilationSummary: {isChatUpdated: true}
					}

					return [...previousValue.slice(0, chatIndex), updatedChat, ...previousValue.slice(chatIndex + 1)]
				})
				setChatMessageLoading(true)
				generateAiOutputHandler(modelId, false, userPrompt)
			}
			setAiOutputLoading(false)
			setUnsavedChanges(true)
			setUserPrompt('')
			setExpandPromptOptimization(false)
			setTimeout(() => {
				autoScroll.current = true
				messagesEndRef.current?.scrollIntoView({behavior: 'smooth', block: 'end'})
			}, 200)
		}
	}

	const handleEnterKeyPressed = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			handleSend()
		}
	}

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (aiPrompt !== '') {
			setAiPrompt(event.target.value)
		} else {
			setUserPrompt(event.target.value)
		}
	}

	const regenerateOutputHandler = (regenerateFromError?: boolean) => {
		if (aiOutputLoading) return

		setChatMessageLoading(true)
		setRegenerateChatMessageLoading(true)
		const updatedChatMessages = promptChatDetails.messages
		const lastUserPromptIndex = updatedChatMessages.length - 2
		const messageToRegenerate = updatedChatMessages.pop()

		setPromptChats((previousValue: PromptChatDetail[]) => {
			const previousChatUpdated = previousValue.find(previousChat => previousChat.modelId === modelId)
			if (!previousChatUpdated) return previousValue

			previousChatUpdated.messages = updatedChatMessages
			return Object.assign([], previousValue)
		})
		const regeneratedOutputs = [...(messageToRegenerate!.regeneratedOutputs || []), messageToRegenerate!.text]
		generateAiOutputHandler(modelId, true, updatedChatMessages[lastUserPromptIndex].text, regenerateFromError ? [] : regeneratedOutputs).then(() => {
			setAiOutputLoading(false)
			setRegenerateChatMessageLoading(false)
		})
		autoScroll.current = true
		messagesEndRef.current?.scrollIntoView({behavior: 'smooth', block: 'end'})
	}

	const handleOnScroll = () => {
		if (!autoScroll.current) {
			setUserHasScrolledUp(true)
		}
		autoScroll.current = false
	}

	const promptOptimizationChatHandler = () => {
		setExpandPromptOptimization(true)
		setIsPromptOptimizationChat(true)
	}

	return <Box className='chatContainer'>
		<Box className='chatMessageContainer' onScroll={handleOnScroll}>
			{messages.map((message, index) =>
				<Message key={`message-${index}`} message={message} modelId={modelId}
				         isLastMessage={promptChatDetails.messages.length - 1 === index}
				         onRegenerateOutput={() => regenerateOutputHandler(false)}/>)}
			{chatMessageLoading &&
                <MessageLoading message={{text: regenerateChatMessageLoading ? 'Regenerating output...' : 'Generating output...', sender: 'bot'}} modelId={modelId}/>}
			{aiPromptLoading &&
				<MessageLoading message={{text: regenerateChatMessageLoading ? 'Regenerating prompt...' : 'Generating prompt...', sender: 'user'}} modelId={modelId} isUserMessage={true}/>}
			<Box ref={messagesEndRef}></Box>
		</Box>
		<Box className='chatInputContainer'>
			<Box className='chatInput'>
				<TextField
					fullWidth
					multiline
					placeholder='Enter response here'
					variant='outlined'
					onKeyDown={handleEnterKeyPressed}
					value={aiPrompt !== '' ? aiPrompt : userPrompt}
					onChange={handleInputChange}
					className={`promptInput ${expandPromptOptimization ? 'hidden' : ''}`}
					maxRows={10}
					InputProps={{
						endAdornment: <InputAdornment position='end'><SendIcon className='sendIcon' onClick={handleSend}/></InputAdornment>
					}}
				/>
				<Box>
					<Button startIcon={<MagicWandIcon/>} onClick={promptOptimizationChatHandler}
					        className={`promptOptimizationButton promptOptimizationButtonChat ${expandPromptOptimization ? 'hidden' : ''}`}>Prompt
						optimization</Button>
				</Box>
			</Box>
			<Box className='advancedSettingsContainer'>
				<AiAdvancedSettings expandPromptOptimization={expandPromptOptimization} setExpandPromptOptimization={setExpandPromptOptimization}
				                    isPromptOptimizationChat={isPromptOptimizationChat} setIsPromptOptimizationChat={setIsPromptOptimizationChat}
				                    handleSend={handleSend} handleInputChange={handleInputChange} handleEnterKeyPressed={handleEnterKeyPressed}/>
			</Box>
		</Box>
		<Drawer anchor='right' open={displayDrawerSaveChatCompilation}
		        onClose={toggleDrawer(false, 'SAVE_CHAT_COMPILATION_DRAWER')}>
			<SaveChatCompilation promptChatDetails={promptChatDetails} tempPromptId={tempPromptId}/>
		</Drawer>
		<Drawer anchor='right' open={displayDrawerSaveChatHistory}
				onClose={toggleDrawer(false, 'SAVE_CHAT_HISTORY_DRAWER')}>
			<SaveChatHistory modelId={modelId} tempPromptId={tempPromptId}/>
		</Drawer>
	</Box>
}