import React from 'react'
import {useNavigate} from 'react-router-dom'
import {TrackActionEvent} from '../../service/SegmentService'
import { useUser } from '@clerk/clerk-react'

interface NarusLogoProps {
	width?: number
	height?: number
}

export const NarusLogo = ({width, height}: NarusLogoProps) => {

	const navigate = useNavigate()
	const {user} = useUser()

	const iconClickHandler = () => {
		TrackActionEvent('Menu clicked', user?.id, {path: '/'})
		navigate('/')
	}

	return <div className='headerLogo' onClick={iconClickHandler}>
		<svg xmlns="http://www.w3.org/2000/svg" width={width || 51} height={height || 51} viewBox="0 0 60 60" fill="none">
			<rect width="60" height="60" rx="12" fill="#151C33"/>
			<path
				d="M22 21.5H27.088L34.312 33.308H34.36V21.5H38.104V38.492H33.208L25.792 26.396H25.744V38.492H22V21.5Z"
				fill="#EAEADF"/>
		</svg>
	</div>
}