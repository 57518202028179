import {createTheme} from '@mui/material'


export const loadMoreButtonTheme = createTheme({
	palette: {
		primary: {
			main: '#006D7A',
			light: '#006D7A',
			dark: '#006D7A',
			contrastText: '#006D7A',
		},
		secondary: {
			main: '#006D7A'
		},
	},
	typography: {
		button: {
			textTransform: "none"
		}
	}
})

export const customTheme = createTheme({
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					fontFamily: 'Avenir Next',
					textDecoration: 'underline',
					fontSize: 14,
					fontWeight: 700,
					color: '#006D7A'
				}
			}
		},
		MuiButton : {
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						backgroundColor: '#EAEADF',
						color: '#FFFFFF'
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontFamily: 'Avenir Next',
					fontSize: 16,
					fontWeight: 500,
					color: 'rgba(0, 0, 0, 0.60)',
				},
			}
		}
	},
	palette: {
		primary: {
			main: '#006D7A',
			light: '#006D7A',
			dark: '#006D7A',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#006D7A'
		},
	},
	typography: {
		allVariants: {
			fontFamily: 'Avenir Next'
		},
		button: {
			textTransform: "none"
		},
		subtitle1: {
			color: '#44546F',
			fontSize: 12,
			fontWeight: 400,
		},
		body1: {
			fontWeight: 500,
		},
		body2: {
			color: '#151C33',
			fontSize: 12,
			fontWeight: 600
		},
		h3: {
			color: '#151C33',
			fontSize: 24,
			fontWeight: 700,
		},
		h4: {
			color: '#172B4D',
			fontSize: 20,
			fontWeight: 700,
			margin: '0 0 12px 0'
		}
	},
})