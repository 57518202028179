import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material'
import React from 'react'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import {StatisticByModel} from '../../../hooks/useStatistics'
import Spinner from '../../spinner/Spinner'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import './CompanyWorkspace.scss'
import companyHeader from '../../../images/Img-Admin-side.png'
import adaptavistLogo from '../../../images/adaptavist-logo.png'
import {useUsersAccessContext} from '../../../context/UsersAccessContext'
import {useOrganizationContext} from '../../../context/OrganizationContext'

export const CompanyWorkspace = () => {

	const {organization} = useOrganizationContext()
	const {loading, totalUsersByModel} = useStatisticsContext()
	const {usersInfo} = useUsersAccessContext()

	return <Box className='companyWorkspaceContainer'>
		<Box className='companyHeaderContainer'>
			<img src={companyHeader} alt='company header' className='companyHeaderImage'/>
			<img src={adaptavistLogo} alt='company logo' className='companyLogo'/>
		</Box>
		<Box className='companyWorkspaceWrapper'>
			<Typography variant='h4' className='companyWorkspaceTitle'>{organization?.name ?? ''} Workspace</Typography>
			<Typography className='tableTitle'>Assigned Users per Model</Typography>
			<Grid container>
				<Grid item xs={12}>
					{loading && <Spinner/>}
					{!loading && <Table size='small'>
	                    <TableHead>
	                        <TableRow className='modelUsersTableHeader'>
	                            <TableCell><AccountCircleIcon className='userIcon'/>Total Users</TableCell>
	                            <TableCell className='modelUserTotalHeaderCell'>{usersInfo.length}</TableCell>
	                        </TableRow>
	                    </TableHead>
	                    <TableBody className='modelUsersTableBody'>
							{totalUsersByModel.map((userStatisticByModel: StatisticByModel, index: number) => <TableRow key={`row-user${userStatisticByModel.name}`} className={`modelUserTableRow modelUserTableRow${index}`}>
									<TableCell>Users in {userStatisticByModel.name}</TableCell>
									<TableCell align="right">{userStatisticByModel.value}</TableCell>
								</TableRow>
							)}
	                    </TableBody>
	                </Table>
					}
				</Grid>
			</Grid>
		</Box>
	</Box>
}