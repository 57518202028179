export const formatDateStringToLocaleDate = (seconds: number): string => {
	const date = new Date(seconds)
	if (isNaN(date.getTime())) throw new Error('Invalid date string')

	return date.toLocaleDateString(navigator.language, {year: 'numeric', month: '2-digit', day: '2-digit'})
}

export const convertToStartOfDay = (date: Date): Date => {
	const startOfDay = new Date(date)
	startOfDay.setUTCHours(0, 0, 0, 0)
	return startOfDay
}

export const getDateAndTime = (dateSeconds: number): string => {
	const date = new Date(dateSeconds * 1000)
	return date.toLocaleString(navigator.language)
}