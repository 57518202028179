import {useEffect} from 'react'
import {PageView} from '../../service/SegmentService'
import {Grid} from '@mui/material'
import HeaderMenu from '../headerMenu/HeaderMenu'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {usePromptsContext} from '../../context/PromptsContext'
import {EmptyPrompts} from '../emptySection/EmptyPrompts'

export const MyPrompts = () => {
    const {userPrompts} = usePromptsContext()

    useEffect(() => PageView('My prompts'), [])

    return <Grid container>
        <Grid xs={12} item>
            <HeaderMenu/>
        </Grid>
        <Grid xs={12} item>
            <PromptsCards
                prompts={userPrompts}
                showLoadMore={false}
                customEmptyPromptsElement={<EmptyPrompts/>}/>
        </Grid>
    </Grid>
}
