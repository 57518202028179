import React from 'react'
import {Box, Grid, Typography} from '@mui/material'
import './CostPerAIModel.scss'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import PaymentsIcon from '@mui/icons-material/Payments'
import {NoDataAvailable} from './NoDataAvailable'
import Spinner from '../../spinner/Spinner'
import {AiModelIcon} from '../../aiModelIcon/AiModelIcon'
import {roundNumber} from '../../../helpers/NumberHelpers'

export const CostPerAIModel = () => {

	const {usageStatistics, loading, top3ModelsByCost} = useStatisticsContext()

	return <Box className='expenditureContainer'>
		<Typography className='aiChartTitle expenditure' variant='h3'>AI Expenses</Typography>
		{loading && <Grid item xs={12} className='spinnerContainer'><Spinner/></Grid>}
		{!loading && !usageStatistics?.modelCostStatistics && <NoDataAvailable/>}
		{!loading && usageStatistics?.modelCostStatistics && <>
            <Box className='totalCostContainer'>
                <Box className='iconContainer'>
                    <PaymentsIcon/>
                </Box>
                <Box>
                    <Typography variant='h3' className='expensesNumber'>$ {roundNumber(usageStatistics.modelCostStatistics.totalCostAllModels)}</Typography>
                    <Typography variant='subtitle2'>Total spent</Typography>
                </Box>
            </Box>
            <hr className='dividerExpenditure'/>
            <Box className='breakdownContainer'>
                <Typography className='breakdownTitle' variant='h4'>Breakdown by AI</Typography>
                <Grid container rowSpacing={1} className='breakdownTable'>
					{top3ModelsByCost.map(({modelId, modelName, cost}, index) => <Grid item xs={8} md={4} key={`${modelId}-${index}`}>
							<Box className='aiModelCost'>
								<Box><AiModelIcon modelId={modelId} className={''}/></Box>
								<Typography variant='subtitle2'>{modelName}</Typography>
							</Box>
							<Typography variant='h5'>$ {cost}</Typography>
						</Grid>
					)}
                </Grid>
            </Box>
        </>}
	</Box>
}