import React from 'react'
import './HeaderMenu.scss'
import {Grid, MenuItem} from '@mui/material'
import Box from '@mui/material/Box'
import {NarusLogo} from '../narusLogo/NarusLogo'
import '../homeTabs/homeTabs.scss'
import {UserProfileMenu} from './profileMenu/ProfileMenu'
import {useLocation, useNavigate} from 'react-router-dom'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'


const HeaderMenu = () => {

    const {user} = useUser()
    const navigate = useNavigate()
    const location = useLocation()

    const onClickHandler = (path: string) => {
        TrackActionEvent('Menu clicked', user?.id, {path})
        navigate(path)
    }

    return (
        <Grid xs={12} className='headerWrapper' item>
            <Box className='header'>
                <NarusLogo/>
                <Box className='headerMenuContainer'>
                    <MenuItem className={location.pathname.startsWith('/inspirationPrompts') ? 'selected' : ''} onClick={() => onClickHandler('/inspirationPrompts')}>Inspiration prompts</MenuItem>
                    <MenuItem className={location.pathname.startsWith('/chats') ? 'selected' : ''} onClick={() => onClickHandler('/chats')}>Chats</MenuItem>
                    <MenuItem className={location.pathname.startsWith('/myPrompts') ? 'selected' : ''} onClick={() => onClickHandler('/myPrompts')}>My prompts</MenuItem>
                </Box>
                <UserProfileMenu/>
            </Box>
        </Grid>
    )
}

export default HeaderMenu