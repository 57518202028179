import {useState, ChangeEvent} from 'react'
import './SearchBar.scss'
import {useSearchContext} from '../../context/SearchContext'
import ClearIcon from '@mui/icons-material/Clear'
import {Alert, Box, Button, InputAdornment, TextField, Typography} from '@mui/material'
import {useDebouncedCallback} from 'use-debounce'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import {AiModelSelector} from '../aiModelSelector/AiModelSelector'
import Spinner from '../spinner/Spinner'
import EmptyPromptsList from '../emptyPromptsList/EmptyPromptsList'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {AiAdvancedSettings} from '../aiAdvancedSettings/AiAdvancedSettings'
import {useNavigate} from 'react-router-dom'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {ReactComponent as MagicWandIcon} from '../../images/magic-wand.svg'
import { getSelectedModelIds, isAnyModelSelected } from '../../utils/aiModelUtils'

const SearchBar = () => {
	const {
		searchLoading,
		searchedPrompts,
		lastEvaluatedKeySearch,
		archivedPromptsChecked,
		currentPromptsChecked,
		setSearchLoading,
		setSearchText,
		modelsSelected,
		isDefaultSearch,
		searchUserPrompts,
		clearSearch,
		setIsLongTextSearch,
		isLongTextSearch,
		setDefaultSelectedModels,
	} = useSearchContext()

	const {
		audience, tone, format, modelId, userPrompt, isOptimizedPrompt, setModelId,
		setValueHandler, setModelIds, setUserPrompt, setPromptChats, setAiPrompt
	} = usePromptCreationContext()
	const {user} = useUser()
	const navigate = useNavigate()

	const [expandPromptOptimization, setExpandPromptOptimization] = useState(false)

	const sendButtonDisabled = !userPrompt || !isAnyModelSelected(modelsSelected)

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value
		setUserPrompt(inputText)
		setSearchText(inputText)
		setSearchLoading(inputText.length > 0)
	}

	const handleClickClear = () => {
		TrackActionEvent('Search', user?.id, {action: 'clear'})
		setIsLongTextSearch(false)
		setUserPrompt('')
		setAiPrompt('')
	}

	const createPromptHandler = async () => {
		const createPromptAnalyticsInfo = {
			model: modelId,
			action: 'done'
		}

		if (tone) createPromptAnalyticsInfo['tone'] = tone
		if (audience) createPromptAnalyticsInfo['audience'] = audience
		if (format) createPromptAnalyticsInfo['format'] = format
		TrackActionEvent('Create prompt', user?.id, createPromptAnalyticsInfo)

		const models = getSelectedModelIds(modelsSelected)
		setValueHandler(setModelIds, models, 'modelIds')
		setValueHandler(setModelId, models[0], 'modelId')

		setDefaultSelectedModels()

		setPromptChats(models.map(modelId => ({
			modelId,
			messages: !isOptimizedPrompt() ? [{sender: 'user', text: userPrompt, optimized: false}] : []
		})) as PromptChatDetail[])

		setExpandPromptOptimization(false)
		clearSearch()
		if (!isOptimizedPrompt()) setUserPrompt('')
		navigate(isOptimizedPrompt() ? '/aiChat/optimized' : '/aiChat')
	}

	const searchHandler = useDebouncedCallback(async (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			await createPromptHandler()
			return
		}

		if (isDefaultSearch || (!archivedPromptsChecked && !currentPromptsChecked)) {
			setSearchLoading(false)
			clearSearch()
			return
		}
		searchUserPrompts(true)
		TrackActionEvent('Search', user?.id, {action: 'search'})
	}, 1000)

	const displaySearchResult = () => {
		if (searchLoading) return <Box className='noPrompts'>We’re searching for prompts... <Spinner/></Box>
		else if (searchedPrompts.length === 0) return <EmptyPromptsList message='Please type in the search bar to get results.'/>
		return <PromptsCards prompts={searchedPrompts} showLoadMore={lastEvaluatedKeySearch !== undefined}/>
	}

	return <Box>
		<Box className='searchPromptContainer'>
			<Box className='searchPromptBarOptimization'>
				<Box display='flex' alignItems='flex-start'>
					<TextField
						multiline={true}
						autoFocus={true}
						className={`searchBar ${expandPromptOptimization ? 'expanded' : ''}`}
						onChange={handleChange}
						onKeyDown={searchHandler}
						value={userPrompt}
						placeholder='Write your prompt here'
						InputProps={{
							startAdornment: <InputAdornment position='start'>
								<CommentIcon label='write prompt' primaryColor='#006D7A'/>
							</InputAdornment>,
							endAdornment: <InputAdornment position='end' onClick={() => userPrompt ? handleClickClear() : undefined}>
								{userPrompt ? <ClearIcon className='searchIcon'/> : ''}
							</InputAdornment>
						}}
					/>
					<Box>
						<Button startIcon={<MagicWandIcon/>} onClick={() => setExpandPromptOptimization(true)}
						        className={`promptOptimizationButton ${expandPromptOptimization ? 'hidden' : ''}`}>Prompt
							optimization</Button>
					</Box>
				</Box>
				<Box>
					<AiAdvancedSettings expandPromptOptimization={expandPromptOptimization} setExpandPromptOptimization={setExpandPromptOptimization}/>
				</Box>
			</Box>
			<Box className='searchSettingsContainer'>
				<AiModelSelector/>
				<Box className='createPromptContainer'>
					<Button variant='contained' disabled={sendButtonDisabled} onClick={createPromptHandler}>Send to AI</Button>
				</Box>
			</Box>
		</Box>
		<Box className='searchResultsContainer'>
			<Typography variant='h4'>Narus library search results</Typography>
			<Alert severity='info' className='fixedInfoAlert'>Library results may be inaccurate with long
				texts.</Alert>
			{isLongTextSearch ?
				<Typography variant='body2'>The results of the search might not be accurate for long prompts. Type a
					shorter one for better results.</Typography> : displaySearchResult()}
		</Box>
	</Box>
}

export default SearchBar