import React, {useMemo} from 'react'
import {GridColDef} from '@mui/x-data-grid'
import {AIModelID} from '../../../types/AiModel'
import {Box, Typography} from '@mui/material'
import './UserUsageTable.scss'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import {DataGridTable} from '../DataGridTable'

export const UserUsageTable = () => {

	const columns: GridColDef[] = useMemo(() => [
		{field: 'userFullName', headerName: 'Name', flex: 1},
		{field: 'totalPrompts', headerName: 'Total prompts', flex: 1},
		{field: AIModelID.GPT_3_5, headerName: 'ChatGPT 3.5', valueGetter: (_, row) => row.totalPromptsByModel[AIModelID.GPT_3_5], flex: 1},
		{field: AIModelID.GPT_4, headerName: 'ChatGPT 4', valueGetter: (_, row) => row.totalPromptsByModel[AIModelID.GPT_4], flex: 1},
		{field: AIModelID.GOOGLE_GEMINI, headerName: 'Gemini', valueGetter: (_, row) => row.totalPromptsByModel[AIModelID.GOOGLE_GEMINI], flex: 1},
		{field: AIModelID.LLAMA_2, headerName: 'Llama 2', valueGetter: (_, row) => row.totalPromptsByModel[AIModelID.LLAMA_2], flex: 1},
		{field: AIModelID.MISTRAL, headerName: 'Mistral', valueGetter: (_, row) => row.totalPromptsByModel[AIModelID.MISTRAL], flex: 1},
		{field: AIModelID.CLAUDE, headerName: 'Claude', valueGetter: (_, row) => row.totalPromptsByModel[AIModelID.CLAUDE], flex: 1},
		{field: 'totalCost', headerName: 'Total cost', valueGetter: (value) => `$${Math.round((value + Number.EPSILON) * 100) / 100}`, flex: 1},
	], [])

	const {usageStatistics, loading} = useStatisticsContext()

	return <Box className='usageTableContainer'>
		<Typography className='usageTableTitle' variant='h3'>User interactions</Typography>
		<DataGridTable
			columns={columns}
			rows={usageStatistics?.userUsageStatistics ?? []}
			loading={loading}
			analyticEventName='Usage table'
			currentTable='USER_USAGE'
		/>
	</Box>
}