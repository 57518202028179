import {Box, Button, Typography} from "@mui/material";
import './AdminErrorScreen.scss'
import {useNavigate} from 'react-router-dom'
import HeaderMenu from '../../headerMenu/HeaderMenu'
import LockIcon from '@mui/icons-material/Lock'

export const AdminErrorScreen = () => {

    const navigate = useNavigate()

    return <>
        <HeaderMenu/>
        <Box className='adminErrorScreenWrapper'>
            <Box className='adminErrorScreenContainer'>
                <Box>
                    <svg width={0} height={0}>
                        <defs>
                            <linearGradient id='linearColors' x1='15.5' y1='0.331055' x2='15.5' y2='20.6689' gradientUnits='userSpaceOnUse'>
                                <stop stopColor='#6BC4D0' stopOpacity='0.3'/>
                                <stop offset='1' stopColor='#C8C4D0' stopOpacity='0.5'/>
                            </linearGradient>
                        </defs>
                    </svg>
                    <LockIcon sx={{fontSize: 100, fill: 'url(#linearColors)'}}/>
                </Box>
                <Typography variant='h3'>Oops! It seems like you don’t have access to this section of Narus </Typography>
                <Typography variant='h5'>Please contact the administrator to request admin level access.</Typography>
                <Button onClick={() => navigate('/')}>Go to home</Button>
            </Box>
        </Box>
    </>
}