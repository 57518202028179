import {AIModel, AIModelID, allAIModels} from '../types/AiModel'

export const isModelEnabledForUser = (aiModelId: AIModelID, aiModels: AIModel[], disabledAIModels: AIModelID[] | undefined) => {
	return aiModels.find(aiModel => aiModel.id === aiModelId)?.isEnabled && disabledAIModels?.find(disabledModelId => disabledModelId === aiModelId) === undefined
}

export const getAiModelsList = (storedAiModels: AIModel[]) => {
    const storedModelIds = new Set(storedAiModels.map((model) => model.id))
    return allAIModels
        .filter((aiModel: AIModel) => !storedModelIds.has(aiModel.id))
        .map((filterModel: AIModel) => ({label: filterModel.id, value: filterModel.id}))
}