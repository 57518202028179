import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    InputAdornment, TextField,
    Tooltip,
    Typography
} from '@mui/material'
import './AiAdvancedSettings.scss'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {SingleValue} from 'react-select'
import {SelectOption} from '../../types/SelectOption'
import CreatableSelect from 'react-select/creatable'
import {createSelectOption} from '../../helpers/LabelHelpers'
import HelpIcon from '@mui/icons-material/Help'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {AiAdvancedSettingsProps} from '../../types/AiAdvancedSettingsProps'
import CloseIcon from '@mui/icons-material/Close'
import magicWandIcon from '../../images/magic-wand.svg'
import {AiAdvancedSettingsDialog} from './AiAdvancedSettingsDialog'
import SendIcon from '@mui/icons-material/Send'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'

export const AiAdvancedSettings = ({
                                       expandPromptOptimization,
                                       setExpandPromptOptimization,
                                       isPromptOptimizationChat,
                                       setIsPromptOptimizationChat,
                                       handleEnterKeyPressed,
                                       handleInputChange,
                                       handleSend
                                   }: AiAdvancedSettingsProps) => {

    const {tone, setTone, format, setFormat, language, setLanguage, setValueHandler, aiPrompt, userPrompt} = usePromptCreationContext()

	const defaultTones = ['Professional', 'Friendly', 'Persuasive', 'Formal', 'Funny', 'Confident', 'Bold', 'Witty', 'Empathetic']
	const defaultFormat = ['E-mail', 'One-pager', 'Bullet points', 'One paragraph', 'Essay', 'White paper', 'Letter', 'Memorandum', 'Contract']
	const defaultLanguages = ['English', 'Spanish', 'French', 'Chinese']

    const [showSliders, setShowSliders] = useState<boolean>(false)

	const onChangeAccordionHandler = () => {
		if (setExpandPromptOptimization) setExpandPromptOptimization(previousState => !previousState)
	}

    const closePromptOptimizationDialogHandler = () => {
        setIsPromptOptimizationChat?.(false)
        setExpandPromptOptimization(false)
    }

    return <>
        <Accordion className='advancedSettingsAccordion' expanded={expandPromptOptimization}
                   onChange={onChangeAccordionHandler}>
            <AccordionSummary className='advancedSettingsAccordionSummary'></AccordionSummary>
            <AccordionDetails className={`advancedSettingsAccordionDetails ${expandPromptOptimization && isPromptOptimizationChat ? 'expanded' : ''}`}>
                <Box className='aiAdvancedSettingsContainer'>
                    <Box display='flex' justifyContent='space-between'>
                        <Box display='flex' alignItems='center'>
                            <Box className={`${isPromptOptimizationChat ? '' : 'magicWandCircle'}`}>
                                <img src={magicWandIcon} alt='magic-wand' className={`${isPromptOptimizationChat? 'magicWandIconChat' : 'magicWandIcon'}`} />
                            </Box>
                            <Typography variant='body1'>Prompt optimization</Typography>
                        </Box>
                        <Box>
                            {isPromptOptimizationChat ? <SettingsIcon className='cogIcon' onClick={() => setShowSliders(true)} /> : <></>}
                            <CloseIcon cursor='pointer' onClick={closePromptOptimizationDialogHandler}/>
                        </Box>
                    </Box>
                    <Box className={`${isPromptOptimizationChat ? '' : 'optimizationSettingsContainer'}`}>
                        {!isPromptOptimizationChat ? <>
                            <Button startIcon={<SettingsOutlinedIcon/>} className='settingsButton' onClick={() => setShowSliders(true)}>Settings</Button>
                            <Typography className='explain'>Change the values to get a new prompt that helps you get results optimized for your needs.</Typography>
                        </> : <></>}
                        <Box className={`fieldsContainer ${isPromptOptimizationChat ? 'fieldsContainerChat' : ''}`}>
                            <Typography variant='subtitle1' className='fieldLabel'>Tone of voice</Typography>
                            <Typography variant='subtitle1' className='fieldLabel'>Format<Tooltip
                                title='Specify how you want the output to be structure, for example One paragraph'><HelpIcon
                                className='helpIcon'
                                fontSize='inherit'/></Tooltip></Typography>
                            <Typography variant='subtitle1' className='fieldLabel'>Language</Typography>
                            <CreatableSelect isClearable
                                             menuPlacement='top'
                                             options={tone ? [...defaultTones, tone].map(createSelectOption) : defaultTones.map(createSelectOption)}
                                             value={tone ? createSelectOption(tone) : null}
                                             placeholder='Type or select an option' className='creatableSelect'
                                             onChange={(event: SingleValue<SelectOption>) => setValueHandler(setTone, event?.value ?? '', 'tone')}/>
                            <CreatableSelect isClearable options={defaultFormat.map(createSelectOption)}
                                             menuPlacement='top'
                                             value={format ? createSelectOption(format) : null}
                                             placeholder='Type or select an option' className='creatableSelect'
                                             onChange={(event: SingleValue<SelectOption>) => setValueHandler(setFormat, event?.value ?? '', 'format')}/>
                            <CreatableSelect isClearable options={defaultLanguages.map(createSelectOption)}
                                             menuPlacement='top'
                                             defaultValue={createSelectOption(language)}
                                             placeholder='Ex: English'
                                             className='creatableSelect'
                                             onChange={(event: SingleValue<SelectOption>) => setValueHandler(setLanguage, event?.value ?? '', 'language')}/>
                        </Box>
                    </Box>
                    {isPromptOptimizationChat ? <>
                        <hr className='divider'></hr>
                        <TextField
                            fullWidth
                            multiline
                            placeholder='Enter response here'
                            variant='standard'
                            onKeyDown={handleEnterKeyPressed}
                            value={aiPrompt !== '' ? aiPrompt : userPrompt}
                            onChange={handleInputChange}
                            className='promptInputChat'
                            maxRows={10}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'><SendIcon className='sendIcon' onClick={handleSend}/></InputAdornment>,
                                disableUnderline: true
                            }}
                        />
                    </> : <></>}
                </Box>
            </AccordionDetails>
        </Accordion>
        <AiAdvancedSettingsDialog showSliders={showSliders} setShowSliders={setShowSliders} />
    </>
}