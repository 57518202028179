import {useUser} from '@clerk/clerk-react'
import {DataGrid, GridCallbackDetails, GridColDef, GridColumnVisibilityModel, GridLogicOperator} from '@mui/x-data-grid'
import React, {useState} from 'react'
import {objectsAreEquals} from '../../helpers/ObjectHelpers'
import {TrackActionEvent} from '../../service/SegmentService'
import {GridFilterModel} from '@mui/x-data-grid/models/gridFilterModel'
import {GridPaginationModel} from '@mui/x-data-grid/models/gridPaginationProps'
import {DataGridCustomToolbar} from './charts/DataGridCustomToolbar'
import {v4 as uuidv4} from 'uuid'

export type TableName = 'AUDITING_LOGS' | 'USER_USAGE' | 'USERS_LIST'

declare module '@mui/x-data-grid' {
	interface ToolbarPropsOverrides {
		currentTable: TableName
	}
}

type DataGridTableProps = {
	columns: GridColDef[]
	rows: any[]
	loading: boolean
	analyticEventName: string
	pageSize?: number
	currentTable: TableName
	fieldToSort?: string
}

export const DataGridTable = ({columns, rows, loading, analyticEventName, pageSize, currentTable, fieldToSort}: DataGridTableProps) => {
	const {user} = useUser()

	const initialColumnsVisibility = columns.reduce(((current: Object, column: GridColDef) => {
		current[column.field] = true
		return current
	}), {})

	const [currentPage, setCurrentPage] = useState<number>(0)
	const [currentVisibleColumns, setVisibleColumns] = useState(initialColumnsVisibility)

	const columnsChangeHandler = (model: GridColumnVisibilityModel) => {
		const visibleColumns = columns.reduce(((current: Object, column: GridColDef) => {
			current[column.field] = model[column.field] ?? true
			return current
		}), {})

		if (!objectsAreEquals(visibleColumns, currentVisibleColumns)) {
			TrackActionEvent(analyticEventName, user?.id, {action: 'set_columns', visible_columns: visibleColumns})
			setVisibleColumns(visibleColumns)
		}
	}

	const filterHandler = (model: GridFilterModel, details: GridCallbackDetails) => {
		if (details.reason) TrackActionEvent(analyticEventName, user?.id, {action: 'filter', filter_fields: model.items.map(item => item.field)})
		else TrackActionEvent(analyticEventName, user?.id, {action: 'quick_search'})
	}

	const paginationHandler = (model: GridPaginationModel) => {
		if (model.page !== currentPage) {
			TrackActionEvent(analyticEventName, user?.id, {action: 'paginate'})
			setCurrentPage(model.page)
		}
	}

	return <DataGrid
		rows={rows ?? []}
		columns={columns}
		getRowId={row => row.userId ?? uuidv4()}
		loading={loading}
		autoHeight={true}
		density='compact'
		onColumnVisibilityModelChange={columnsChangeHandler}
		onFilterModelChange={filterHandler}
		initialState={{
			pagination: {
				paginationModel: {page: 0, pageSize: pageSize ?? 5},
			},
			filter: {
				filterModel: {
					items: [],
					quickFilterLogicOperator: GridLogicOperator.Or,
				},
			},
			sorting: {
				sortModel: fieldToSort ? [{field: fieldToSort, sort: 'desc'}] : []
			}
		}}
		disableDensitySelector
		slots={{toolbar: DataGridCustomToolbar}}
		pageSizeOptions={[5, 10, 25]}
		onPaginationModelChange={paginationHandler}
		slotProps={{
			toolbar: {
				showQuickFilter: true,
				currentTable
			},
		}}
	/>
}