import {Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material"
import React, {useState} from 'react'
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen'
import Button from '@mui/material/Button'


const MobileBanner = () => {

	const isMobile = useCheckMobileScreen()

	const [open, setOpen] = useState(true)

	const handleClose = () => setOpen(false)

	if (!isMobile) return <></>

	return <Dialog
		open={open}
		onClose={handleClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		<DialogContent>
			<DialogContentText >
				For the best experience, please use Narus on desktop.
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} autoFocus>Close</Button>
		</DialogActions>
	</Dialog>
}

export default MobileBanner