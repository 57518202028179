import React from 'react'
import {Box} from '@mui/material'
import ReplyIcon from '@mui/icons-material/Reply'
import './ShareFeedback.scss'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

const ShareFeedback = () => {

	const {user} = useUser()

	const openFeedbackForm = () => {
		TrackActionEvent('Share feedback', user?.id)
		window.open('https://adaptavist-group.atlassian.net/wiki/spaces/KOLEKTI/pages/276955145/Narus+feedback', '_blank', 'noreferrer')
	}

	return <Box className='shareFeedbackContainer' onClick={openFeedbackForm}>
		Share Feedback <ReplyIcon fontSize='small' className='replyIcon'/>
	</Box>

}

export default ShareFeedback