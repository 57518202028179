import {ReactNode, createContext, useContext, SetStateAction, Dispatch} from 'react'
import {Prompt} from '../types/Prompt'
import {usePrompts} from '../hooks/usePrompts'
import {SelectOption} from '../types/SelectOption'

export type PromptsContextProviderProps = {
	children: ReactNode
}

export interface PromptsContextValue {
	prompts: Prompt[]
	setPrompts: Dispatch<SetStateAction<Prompt[]>>
	favourites: Prompt[]
	setFavourites: Dispatch<SetStateAction<Prompt[]>>
	userPrompts: Prompt[]
	setUserPrompts: Dispatch<SetStateAction<Prompt[]>>
	recentlyUsedPrompts: Prompt[]
	setRecentlyUsedPrompts: Dispatch<SetStateAction<Prompt[]>>
	promptsLoading: boolean
	setPromptsLoading: Dispatch<SetStateAction<boolean>>
	labels: SelectOption[]
	setLabels: Dispatch<SetStateAction<SelectOption[]>>
	teams: SelectOption[]
	setTeams: Dispatch<SetStateAction<SelectOption[]>>
	lastEvaluatedKey: string | undefined
	setLastEvaluatedKey: Dispatch<SetStateAction<string | undefined>>
	getNextPrompts: () => void
	getPromptById: (promptId: string) => Promise<Prompt | undefined>
}


export const DEFAULT_PROMPTS_CONTEXT: PromptsContextValue = {
	prompts: [],
	setPrompts: () => {},
	favourites: [],
	setFavourites: () => {},
	userPrompts: [],
	setUserPrompts: () => {},
	promptsLoading: false,
	setPromptsLoading: () => {},
	labels: [],
	setLabels: () => {},
	teams: [],
	setTeams: () => {},
	lastEvaluatedKey: undefined,
	setLastEvaluatedKey: () => {},
	recentlyUsedPrompts: [],
	setRecentlyUsedPrompts: () => {},
	getNextPrompts: () => {},
	getPromptById: () => Promise.resolve(undefined)
}


export const ReactPromptsContext = createContext<PromptsContextValue>(DEFAULT_PROMPTS_CONTEXT)

export const usePromptsContext = () => useContext(ReactPromptsContext)

export const PromptsContextProvider = ({children}: PromptsContextProviderProps) => {
	const value = usePrompts()

	return <ReactPromptsContext.Provider value={value}>
		{children}
	</ReactPromptsContext.Provider>
}