import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {AIModel, AIModelID} from '../../../../types/AiModel'
import Box from '@mui/material/Box'
import {Alert, Drawer} from '@mui/material'
import Typography from '@mui/material/Typography'
import {AiModelControl} from '../aiModels/AiModelControl'
import React, {useState} from 'react'
import {AiModelsControlEdit} from '../aiModels/AiModelsControlEdit'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'

export const KnowledgeBaseConfig = () => {

	const {aiModels} = useAiModelsContext()
	const {displayDrawerEditAiModel, toggleDrawer} = useToggleDrawerContext()

	const aiModelsOnlyWithAmazonQ = aiModels.filter(model => model.id === AIModelID.AMAZON_Q)

	const [aiModelToEdit, setAiModelToEdit] = useState<AIModel | undefined>(undefined)

	const showNoAiModelsEnabledWarning = () => {
		if (!aiModelsOnlyWithAmazonQ.length || aiModelsOnlyWithAmazonQ.some(model => model.isEnabled)) return <></>
		return <Box className='noModelsEnabledWarning'>
			<Alert severity='warning'>Knowledge bases are disabled for all users. Enable one of them to allow them to use Narus</Alert>
		</Box>
	}

	return <>
		<Box className='aiModelsControlContainer'>
			<Typography variant='h3'>Knowledge bases</Typography>
			<Typography variant='body1'>Enable AI models to make them available to your employees.</Typography>
			{showNoAiModelsEnabledWarning()}
			{aiModelsOnlyWithAmazonQ.length ? <Box className='aiModelsTable'>
				{aiModelsOnlyWithAmazonQ.map((aiModel, index) => <AiModelControl aiModel={aiModel} index={index} onSelectAiModel={setAiModelToEdit} key={`amazonQ-${index}`}/>)}
			</Box> : <Alert severity='warning'>You have no Knowledge bases available, add one to allow your company to use them.</Alert>}
		</Box>
		<Drawer anchor='right' open={displayDrawerEditAiModel}
		        onClose={toggleDrawer(false, 'EDIT_AI_MODEL_DRAWER')}>
			{aiModelToEdit ? <AiModelsControlEdit aiModel={aiModelToEdit}/> : <></>}
		</Drawer>

	</>

}