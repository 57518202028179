import {useEffect, useRef, useState} from 'react'
import './ProfileMenu.scss'
import {UserButton} from '@clerk/clerk-react'
import {Button} from '@mui/material'
import Box from '@mui/material/Box'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import {useNavigate, useLocation} from 'react-router-dom'
import {useUserInfoContext} from '../../../context/UserInfoContext'

export const UserProfileMenu = () => {

    const navigate = useNavigate()
    const { userInfo } = useUserInfoContext()
    const location = useLocation()
    const isAdmin = userInfo?.isAdmin

    const userButtonRef = useRef<HTMLDivElement | null>(null)
    const userAvatarRef = useRef<HTMLDivElement | null>(null)
    const [userMenuOpen, setUserMenuOpen] = useState(false)
    const [switchToView, setSwitchToView] = useState<string>(location.pathname === '/admin' ? 'user' : 'admin')

    useEffect(() => {
        function handleClickOutside(event) {
            if (userButtonRef.current && !userButtonRef.current.contains(event.target)) {
                setUserMenuOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [userButtonRef])

    const handleOpenUserMenu = (event) => {
        if (!userMenuOpen && userAvatarRef.current && !userAvatarRef.current.contains(event.target)) {
            const userAvatar = document.getElementsByClassName('cl-userButtonAvatarImage')[0] as HTMLElement
            userAvatar.click()
        }
        setUserMenuOpen(!userMenuOpen)
    }

    const switchViewHandler = () => {
        setSwitchToView(switchToView === 'admin' ? 'user' : 'admin')
        navigate(switchToView === 'admin' ? '/admin' : '/')
    }

    return <Box className='avatarMenu' ref={userButtonRef}>
        {isAdmin ? <>
            <SupervisorAccountIcon className='adminIcon' onClick={switchViewHandler}/>
            <Button startIcon={<SupervisorAccountIcon/>} onClick={switchViewHandler}
                    className='switchViewButton'>Switch to {switchToView} view</Button></> : <></>}
        <div ref={userAvatarRef} onClick={handleOpenUserMenu}>
            <UserButton showName={false}/>
        </div>
    </Box>
}