import {FC} from 'react'
import {Box} from '@mui/material'
import {UserAuditLog} from '../../../types/UserAuditLog'
import {formatDateStringToLocaleDate} from '../../../helpers/DateHelpers'
import {AiModelIcon} from '../../aiModelIcon/AiModelIcon'
import {AuditLogWarningLevelChip} from './AuditLogWarningLevelChip'
import {getAuditLogHighestWarningLevel} from '../../../utils/userAuditLogUtils'

type AuditingLogDetailInfoProps = {
    auditLog: UserAuditLog
}

export const AuditingLogDetailInfo: FC<AuditingLogDetailInfoProps> = ({
    auditLog
}) => {
    const { aiModelId, aiModelName, conversationDate, isOutputSaved, messagesSentToModel, userFullName } = auditLog

    return <>
        <Box className='auditDataFieldName'>
            Date:
            <span className='auditDataField'>
                {formatDateStringToLocaleDate(conversationDate)}
            </span>
        </Box>
        <Box className='auditDataFieldName'>
            User:
            <span className='auditDataField'>
                {userFullName}
            </span>
        </Box>
        <Box className='auditDataFieldName model'>
            Model:
            <span className='auditDataField modelName'>
                <AiModelIcon modelId={aiModelId}/>
                {aiModelName}
            </span>
        </Box>
        <Box className='auditDataFieldName'>
            Messages sent:
            <span className='auditDataField'>
                {messagesSentToModel}
            </span>
        </Box>
        <Box className='auditDataFieldName'>
            Save output:
            <span className='auditDataField'>
                {isOutputSaved ? 'Yes' : 'No'}
            </span>
        </Box>
        <Box className='auditDataFieldName'>
            Warning:
            <span className='auditDataField warningLevelField'>
                <AuditLogWarningLevelChip level={getAuditLogHighestWarningLevel(auditLog)}/>
            </span>
        </Box>
    </>
}