import {FC, ReactElement} from 'react'
import {Box, Stack, ThemeProvider} from '@mui/material'
import './PromptsCards.scss'
import EmptyPromptsList from '../emptyPromptsList/EmptyPromptsList'
import PromptCard from '../promptCard/PromptCard'
import Button from '@mui/material/Button'
import {usePromptsContext} from '../../context/PromptsContext'
import {useSearchContext} from '../../context/SearchContext'
import {loadMoreButtonTheme} from '../../themes/CustomThemes'
import CardSkeleton from '../spinner/CardSkeleton'
import {getHomeTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useUserContext} from '../../context/UserContext'
import {Prompt} from '../../types/Prompt'

type PromptsCardsProps = {
    prompts: Prompt[]
    noPromptsMessage?: string
    showLoadMore: boolean
	customEmptyPromptsElement?: ReactElement
}

export const PromptsCards: FC<PromptsCardsProps> = ({
	prompts,
	noPromptsMessage,
	showLoadMore,
	customEmptyPromptsElement
}: PromptsCardsProps) => {

	const {promptsLoading, getNextPrompts} = usePromptsContext()
	const {searchLoading, isDefaultSearch, searchUserPrompts} = useSearchContext()
	const {selectedTab} = useUserContext()
	const {user} = useUser()

	const renderEmptyPrompts = () =>
		customEmptyPromptsElement ?? <EmptyPromptsList message={noPromptsMessage}/>

	const displayPrompts = () => {
		const promptCards = prompts.map((prompt, index) => <PromptCard key={`prompt-${prompt.id}-${index}`} prompt={prompt}/>)
		return <>
			{promptsLoading ? <CardSkeleton/> : promptCards}
		</>
	}

	const loadMoreHandler = () => {
		TrackActionEvent('Load more', user?.id, {section: getHomeTabForAnalytics(selectedTab)})
		if (isDefaultSearch) getNextPrompts()
		else searchUserPrompts(false)
	}

	return <>
		{ prompts?.length === 0 && !promptsLoading ? renderEmptyPrompts() : <></> }
		<Stack direction='row' spacing={{xs: 1, sm: 2, md: 4}} useFlexGap flexWrap='wrap' className='promptsCardsWrapper'>
			{prompts?.length ? displayPrompts() : <>
					{promptsLoading ? <CardSkeleton/> : ''}
				</>
			}
		</Stack>
		{showLoadMore && !searchLoading && !promptsLoading ? <Box justifyContent='center' display='flex'>
			<ThemeProvider theme={loadMoreButtonTheme}>
				<Button variant='outlined' className='loadMoreButton' onClick={loadMoreHandler}>Load more</Button>
			</ThemeProvider>
		</Box>: ''}
	</>
}
