import {useCallback, useEffect, useMemo, useState} from 'react'
import {searchPrompts} from '../service/persistenceService'
import {useUserContext} from '../context/UserContext'
import {Prompt} from '../types/Prompt'
import {useErrorBoundary} from 'react-error-boundary'
import {AIModelID, DEFAULT_SELECTED_MODELS} from '../types/AiModel'
import { SearchContextValue } from '../context/SearchContext'
import { splitTextAtByteLimit } from '../utils/textUtils'
import { useUserInfoContext } from '../context/UserInfoContext'
import { useAiModelsContext } from '../context/AIModelsContext'
import { isModelEnabledForUser } from '../helpers/AiModelHelper'
import { getSelectedModelIds } from '../utils/aiModelUtils'
import { hasId } from '../utils/genericUtils'

export const usePromptSearch = (): SearchContextValue => {
	const { token } = useUserContext()
	const { aiModels } = useAiModelsContext()
	const { userInfo } = useUserInfoContext()

	const {showBoundary} = useErrorBoundary()

	const [searchLoading, setSearchLoading] = useState(false)
	const [searchText, setSearchText] = useState<string>('')
	const [archivedPromptsChecked, setArchivedPromptsChecked] = useState(false)
	const [currentPromptsChecked, setCurrentPromptsChecked] = useState(true)
	const [searchLabels, setSearchLabels] = useState<string[]>([])
	const [searchTeams, setSearchTeams] = useState<string[]>([])
	const [lastEvaluatedKeySearch, setLastEvaluatedKeySearch] = useState<string>()
	const [searchedPrompts, setSearchedPrompts] = useState<Prompt[]>([])
	const [modelsSelected, setModelsSelected] = useState<Record<AIModelID, boolean>>(DEFAULT_SELECTED_MODELS)
	const [isLongTextSearch, setIsLongTextSearch] = useState(false)

	const isDefaultSearch = searchText.length === 0 && !archivedPromptsChecked && currentPromptsChecked && searchLabels.length === 0 && searchTeams.length === 0

	const clearSearch = useCallback(() => {
		setSearchText('')
		setSearchedPrompts([])
	}, [])	

	const searchUserPrompts = useCallback((newSearch: boolean) => {
		clearSearch()
		setSearchLoading(true)
		const searchParams = {searchText, archived: archivedPromptsChecked, nonArchived: currentPromptsChecked, teams: searchTeams, labels: searchLabels, lastEvaluatedKey: newSearch ? '' : lastEvaluatedKeySearch}
		const textByteSize = new Blob([searchText]).size
		const byteLimit = 100
		if (textByteSize >= byteLimit) {
			const splitText = splitTextAtByteLimit(searchText, byteLimit)
			setSearchText(splitText)
			searchParams.searchText = splitText
			setIsLongTextSearch(true)
		}
			searchPrompts(searchParams, token).then(searchedPromptsResponse => {
			setSearchedPrompts(previousSearchPrompts => [...previousSearchPrompts, ...searchedPromptsResponse.prompts])
			setLastEvaluatedKeySearch(searchedPromptsResponse.lastEvaluatedKey)
		})
			.catch(showBoundary)
			.finally(() => setSearchLoading(false))
	}, [archivedPromptsChecked, currentPromptsChecked, lastEvaluatedKeySearch, searchTeams, searchLabels, searchText, token, showBoundary, clearSearch])

	const setDefaultSelectedModels = useCallback(() => {
		setModelsSelected(prev => {
			const userDisabledAIModels = userInfo?.disabledAIModels ?? []
			const userEnabledAIModels = aiModels.filter(({ id }) => isModelEnabledForUser(id, aiModels, userDisabledAIModels))
			const selectedIds = getSelectedModelIds(prev)
			const validIds = selectedIds.filter(id => userEnabledAIModels.some(hasId(id)))

			if (validIds.length || !userEnabledAIModels.length) {
				return prev
			} else {
				const defaultSelectedModelId = userEnabledAIModels.find(hasId(AIModelID.GPT_3_5 as AIModelID)) ? AIModelID.GPT_3_5 : userEnabledAIModels[0].id
				return { ...prev, [defaultSelectedModelId]: true }
			}
		})
	}, [aiModels, userInfo?.disabledAIModels])

	useEffect(() => {
		setDefaultSelectedModels()
	}, [setDefaultSelectedModels])

	return useMemo(() => ({
		searchLoading, setSearchLoading,
		searchText, setSearchText,
		archivedPromptsChecked, setArchivedPromptsChecked,
		currentPromptsChecked, setCurrentPromptsChecked,
		searchLabels, setSearchLabels,
		searchTeams, setSearchTeams,
		modelsSelected, setModelsSelected,
		searchedPrompts, setSearchedPrompts,
		isLongTextSearch, setIsLongTextSearch,
		lastEvaluatedKeySearch,
		isDefaultSearch,
		clearSearch,
		searchUserPrompts,
		setDefaultSelectedModels,
	}), [
		searchLoading,
		searchText, 
		archivedPromptsChecked,
		currentPromptsChecked,
		searchLabels,
		searchTeams,
		modelsSelected,
		searchedPrompts,
		isLongTextSearch,
		lastEvaluatedKeySearch,
		isDefaultSearch,
		clearSearch,
		searchUserPrompts,
		setDefaultSelectedModels
	])
}