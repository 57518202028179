import {FC, PropsWithChildren} from 'react'
import {PromptsContextProvider} from '../../context/PromptsContext'
import {SearchContextProvider} from '../../context/SearchContext'
import {PromptCreationContextProvider} from '../../context/PromptCreationContext'
import {ToggleDrawerContextProvider} from '../../context/ToggleDrawerContext'
import {AiModelsContextProvider} from '../../context/AIModelsContext'
import {UserInfoContextProvider} from '../../context/UserInfoContext'
import {ChatsContextProvider} from '../../context/ChatsContext'
import {ChatMessagesContextProvider} from '../../context/ChatMessagesContext'

export const HomeContextProvider: FC<PropsWithChildren> = ({
	children
}) => {
	return <PromptsContextProvider>
		<UserInfoContextProvider>
			<AiModelsContextProvider>
				<SearchContextProvider>
					<ChatMessagesContextProvider>
						<PromptCreationContextProvider>
							<ChatsContextProvider>
								<ToggleDrawerContextProvider>
									{children}
								</ToggleDrawerContextProvider>
							</ChatsContextProvider>
						</PromptCreationContextProvider>
					</ChatMessagesContextProvider>
				</SearchContextProvider>
			</AiModelsContextProvider>
		</UserInfoContextProvider>
	</PromptsContextProvider>
}