import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {UserInfo} from '../types/UserInfo'
import {useFeedbackContext} from '../context/FeedbackContext'
import {useUser} from '@clerk/clerk-react'
import { UserInfoContextValue } from '../context/UserInfoContext'
import { getUserInfoByUserIdWithRetries } from '../service/persistenceService'

export const useUserInfo = (): UserInfoContextValue => {
	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {user} = useUser()

	const [loading, setLoading] = useState<boolean>(true)
	const [userInfo, setUserInfo] = useState<UserInfo>()

	const getUserInfo = useCallback(() => {
		if (!user?.id) return
		setLoading(true)
		getUserInfoByUserIdWithRetries(token, user.id).then(userInfo => {
			setUserInfo(userInfo)
			setLoading(false)
		}).catch(error => {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don\'t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
			setUserInfo(undefined)
		}).finally(() => setLoading(false))

	}, [token, showFeedback, user?.id])


	useEffect(() => {
		getUserInfo()
	}, [getUserInfo])

	return useMemo(() => ({
		loading,
		userInfo,
	}), [loading, userInfo])
}