import React from 'react'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import {useFeedbackContext} from '../../context/FeedbackContext'
import { AlertTitle } from '@mui/material'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant='standard' {...props} />
})

const FeedbackMessage = () => {
    const {displayFeedback, feedbackTitle, feedbackMessage, feedbackMessageType, setDisplayFeedback} = useFeedbackContext()

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        setDisplayFeedback(false)
    }

    return <>
        <Snackbar
            open={displayFeedback}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} className='Alert' severity={feedbackMessageType}>
                <AlertTitle>{feedbackTitle}</AlertTitle>
                {feedbackMessage}
            </Alert>
        </Snackbar>
    </>
}

export default FeedbackMessage