export type OpenAIApiKey = string


export interface AIModel {
    id: AIModelID
    name: string
    tokenLimit: number
    isEnabled?: boolean
}

export enum AIModelID {
    GPT_3_5 = 'gpt-3.5-turbo',
    GPT_4 = 'gpt-4-0125-preview',
    GOOGLE_GEMINI = 'GoogleGemini',
    LLAMA_2 = 'Llama-2',
    MISTRAL = 'Mistral',
    AMAZON_Q = 'Amazon-Q',
    CLAUDE = 'claude-3-haiku-20240307'
}

export const AIModels: Record<AIModelID, AIModel> = {
    [AIModelID.GPT_3_5]: {
        id: AIModelID.GPT_3_5,
        name: 'GPT-3.5',
        tokenLimit: 4000,
    },
    [AIModelID.GPT_4]: {
        id: AIModelID.GPT_4,
        name: 'GPT-4',
        tokenLimit: 8000,
    },
    [AIModelID.GOOGLE_GEMINI]: {
        id: AIModelID.GOOGLE_GEMINI,
        name: 'Google Gemini',
        tokenLimit: 2048
    },
    [AIModelID.LLAMA_2]: {
        id: AIModelID.LLAMA_2,
        name: 'Llama 2',
        tokenLimit: 2000
    },
    [AIModelID.MISTRAL]: {
        id: AIModelID.MISTRAL,
        name: 'Mistral',
        tokenLimit: 32000
    },
    [AIModelID.AMAZON_Q]: {
        id: AIModelID.AMAZON_Q,
        name: 'Adaptavist Confluence',
        tokenLimit: 32000
    },
    [AIModelID.CLAUDE]: {
        id: AIModelID.CLAUDE,
        name: 'Claude 3',
        tokenLimit: 4096
    }
}

export const allAIModels = [
	AIModels['gpt-3.5-turbo'],
	AIModels['gpt-4-0125-preview'],
	AIModels['GoogleGemini'],
	AIModels['Mistral'],
	AIModels['Llama-2'],
	AIModels['claude-3-haiku-20240307']
] as const

export const ALL_AI_MODEL_IDS = [
    AIModelID.GPT_3_5,
    AIModelID.GPT_4,
    AIModelID.GOOGLE_GEMINI,
    AIModelID.LLAMA_2,
    AIModelID.MISTRAL,
    AIModelID.AMAZON_Q,
    AIModelID.CLAUDE,
] as const

export const DEFAULT_SELECTED_MODELS: Record<AIModelID, boolean> = {
    [AIModelID.GPT_3_5]: false,
    [AIModelID.GPT_4]: false,
    [AIModelID.GOOGLE_GEMINI]: false,
    [AIModelID.LLAMA_2]: false,
    [AIModelID.MISTRAL]: false,
    [AIModelID.AMAZON_Q]: false,
    [AIModelID.CLAUDE]: false
} as const