import {GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid'
import {Box, Grid, Typography} from '@mui/material'
import './AuditingLogsTable.scss'
import {DataGridTable} from '../DataGridTable'
import {useAuditingLogsContext} from '../../../context/AuditingLogsContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {formatDateStringToLocaleDate} from '../../../helpers/DateHelpers'
import {auditLogWarningGridValueGetter} from '../../../utils/userAuditLogUtils'
import {AuditLogWarningLevel, UserAuditLog} from '../../../types/UserAuditLog'
import {useState} from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {CustomDateRangePicker} from '../CustomDateRangePicker'
import {AuditingLogDetail} from './AuditingLogDetail'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import { AuditLogWarningLevelChip } from './AuditLogWarningLevelChip'

const renderCellSaveOutput = (params: GridRenderCellParams) =>
	params.row.isOutputSaved
		? <CheckCircleIcon className='checkIcon'/>
		: <CancelIcon className='cancelIcon'/>

const renderCellWarning = ({value}: GridRenderCellParams<any, AuditLogWarningLevel>) =>
	value ? <AuditLogWarningLevelChip level={value} /> : <></>

export const AuditingLogsTable = () => {

	const {user} = useUser()
	const {auditingLogs, loading} = useAuditingLogsContext()

	const [auditingLogToDisplay, setAuditingLogToDisplay] = useState<UserAuditLog | undefined>()

	const columns: GridColDef[] = [
		{field: 'conversationDate', headerName: 'Date', flex: 1, sortable: true, valueGetter: (_, row) => formatDateStringToLocaleDate(row.conversationDate)},
		{field: 'aiModelName', headerName: 'Model', flex: 1},
		{field: 'userFullName', headerName: 'User', flex: 1},
		{field: 'messagesSentToModel', headerName: 'Messages sent', flex: 1},
		{field: 'isOutputSaved', headerName: 'Save output', flex: 1, renderCell: renderCellSaveOutput},
		{field: 'warning', headerName: 'Warning', flex: 1, valueGetter: auditLogWarningGridValueGetter, renderCell: renderCellWarning},
		{
			field: 'actions', type: 'actions', headerName: 'Actions', width: 80, getActions: (params: GridRowParams) => [params.row.warnings?.length ?
				<GridActionsCellItem
					icon={<VisibilityIcon className='actionIcon'/>}
					label="Open audit log"
					onClick={(event) => setAuditingLogToDisplay(params.row) }
				/> : <></>]
		}
	]

	const goBack = () => {
		setAuditingLogToDisplay(undefined)
		TrackActionEvent('Audit log details', user?.id, {action: 'go_back'})
	}

	if (auditingLogToDisplay) return <AuditingLogDetail auditingLog={auditingLogToDisplay} goBack={goBack}/>

	return <>
		<Grid item xs={12} className='datePickerContainer'>
			<CustomDateRangePicker currentTab='REPORTS'/>
		</Grid>
		<Grid item xs={12}>
			<Box className='auditingLogsContainer'>
				<Typography className='auditingLogsTitle' variant='h3'>Auditing Logs</Typography>
				<DataGridTable
					fieldToSort='conversationDate'
					columns={columns}
					rows={auditingLogs ?? []}
					loading={loading}
					pageSize={25}
					analyticEventName='Auditing logs table'
					currentTable='AUDITING_LOGS'
				/>
			</Box>
		</Grid>
	</>
}