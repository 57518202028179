import { ChangeEvent, FC, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { areCompanyDomainsValid, getCompanyDomain, isCompanyDomainValid } from '../../utils/accountCreationHelpers'

export const AccountCreationFormCompanyDomains: FC = () => {
    const { form, changeFormValue, changeFormValueValid } = useAccountCreationContext()
    const { companyDomains } = form

    const handleAddDomainClicked = () => {
        changeFormValue('companyDomains', [...companyDomains, ''])
        changeFormValueValid('companyDomains', undefined)
    }

    return <Box className='AccountCreationForm_Control'>
        <Typography variant='h4' className='AccountCreationForm_ControlHeader'>
            Connect user base
        </Typography>
        <Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
            Add the domain you want to connect eg.@adaptavist.com
        </Typography>
        { companyDomains.map((_,i) => <AccountCreationFormCompanyDomainControl key={i} index={i} />) }
        <Box>
            <Button onClick={handleAddDomainClicked}>+ Add domain</Button>
        </Box>
    </Box>
}

const AccountCreationFormCompanyDomainControl: FC<{ index: number}> = ({
    index,
}) => {
    const { form, error, changeFormValue, changeFormValueValid, changeFormValueError } = useAccountCreationContext()
    const [validError, setValidError] = useState<boolean>(false)
    const { companyDomains } = form
    const domain = companyDomains[index] ?? ''
    const showError = validError || !!error.companyDomains
    const helperText = showError ? (error.companyDomains || 'Domain has to follow the example @adaptavist.com') : ''

    const getUpdatedCompanyDomains = (value: string): string[] => [...companyDomains.slice(0,index), value, ...companyDomains.slice(index + 1)]

    const handleDeleteClicked = () => {
        const updatedDomains = [...companyDomains.slice(0,index), ...companyDomains.slice(index + 1)]
        changeFormValue('companyDomains', updatedDomains)
        changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
    }

    const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const updatedDomains = getUpdatedCompanyDomains(event.target.value)
        changeFormValue('companyDomains', updatedDomains)
    }

    const handleInputBlurred = () => {
        const value = getCompanyDomain(domain)
        const updatedDomains = getUpdatedCompanyDomains(value)
        setValidError(value ? !isCompanyDomainValid(value) : false)
        changeFormValue('companyDomains', updatedDomains)
        changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
    }

    const handleInputFocus = () => {
        setValidError(false)
        changeFormValueError('companyDomains', undefined)
        changeFormValueValid('companyDomains', undefined)
    }

    return <div className='AccountCreationForm_ControlItem'>
        <TextField variant='outlined' label='Company domain' error={showError} helperText={helperText} value={domain} onFocus={handleInputFocus} onBlur={handleInputBlurred} onChange={handleInputChanged} placeholder='@adaptavist.com'/>
        <DeleteIcon onClick={handleDeleteClicked}/>
    </div>
}