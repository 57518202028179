import React, {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext} from 'react'
import {DrawerType, useToggleDrawer} from '../hooks/useToggleDrawer'

export interface ToggleDrawerContextValue {
	displayPrePromptCreationDrawer: boolean
	displayDrawerPromptSettings: boolean
	displayDrawerLabels: boolean
	displayDrawerTitle: boolean
	displayDrawerSaveChatCompilation: boolean
	displayDrawerSaveChatHistory: boolean
	displayDrawerEditUser: boolean
	displayDrawerEditAiModel: boolean
	displayDrawerEditOrganizationDomain: boolean
	displayDrawerEditFormatWarning: boolean
	displayDrawerEditTopicWarning: boolean
	displayDrawerEditWordWarning: boolean
	setDisplayPrePromptCreationDrawer: Dispatch<SetStateAction<boolean>>
	toggleDrawer: (open: boolean, drawerType?: DrawerType, promptId?: string) => (event: React.KeyboardEvent | React.MouseEvent) => void
	handleCloseSaveChatDrawer: (eventName: string, id?: string) => void
}

const DEFAULT_TOGGLE_DRAWER_CONTEXT: ToggleDrawerContextValue = {
	displayPrePromptCreationDrawer: false,
	displayDrawerPromptSettings: false,
	displayDrawerLabels: false,
	displayDrawerTitle: false,
	displayDrawerSaveChatCompilation: false,
	displayDrawerSaveChatHistory: false,
	displayDrawerEditUser: false,
	displayDrawerEditAiModel: false,
	displayDrawerEditOrganizationDomain: false,
	displayDrawerEditFormatWarning: false,
	displayDrawerEditTopicWarning: false,
	displayDrawerEditWordWarning: false,
	setDisplayPrePromptCreationDrawer: (displayPrePromptCreationDrawer: SetStateAction<boolean>) => {
	},
	toggleDrawer: (open: boolean, drawerType?: DrawerType, promptId?: string) => event => {
	},
	handleCloseSaveChatDrawer: (eventName: string, id?: string) => {
	}
}

export const ToggleDrawerContext = createContext<ToggleDrawerContextValue>(DEFAULT_TOGGLE_DRAWER_CONTEXT)

export const useToggleDrawerContext = () => useContext(ToggleDrawerContext)

export const ToggleDrawerContextProvider: FC<PropsWithChildren> = ({children}) => {

	const value = useToggleDrawer()

	return <ToggleDrawerContext.Provider value={value}>
		{children}
	</ToggleDrawerContext.Provider>
}